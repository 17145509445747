import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import StatusCard from "../../reusable/Table/TripStatus";
import DropDown from "../../reusable/DropDown";
import DateInput from "../../reusable/DateInput";
import { Modal, message } from "antd";
import Input from "../../reusable/Input";
import Button from "../../reusable/Button";
import Trip from "../../../services/api/tripApi";
import DateUtils from "../../../services/utility/DateUtils";
import TimeInput from "../../reusable/TimeInput";
import ConfirmModal from "../../modals/ConfirmModal";
import ReCAPTCHA from "react-google-recaptcha";
import DownloadButton from "./DownloadButton";

export default function NotAllotedTable({
  setCompanyID,
  setShiftTimeID,
  setFromDate,
  setToDate,
  companyID,
  shiftTimeID,
  fromDate,
  toDate,
  companyList,
  shiftTimeList,
  tripList,
  vehicleTypeData,
  tripTypeList,
  fetchTripData,
  allocatedTypeFilter,
  setAllocatedTypeFilter,
  tripTypeFilter,
  setTripTypeFilter,
  activeKey,
  isLoading,
}) {
  const [copyModal, setCopyModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState([]);

  const [tripEndTime, setTripEndTime] = useState(null);
  const [noOfEmployees, setNoOfEmployees] = useState(null);

  const [id, setId] = useState(null);
  const [routeId, setRouteId] = useState("");
  const [routeName, setRouteName] = useState("");
  const [tripDate, setTripDate] = useState(null);
  const [vehicleType, setVehicleType] = useState("");
  const [shiftTime, setShiftTime] = useState("");
  const [tripType, setTripType] = useState("");

  const [vehicleTypeID, setVehicleTypeID] = useState("");
  const [shiftTimeIDrow, setShiftTimeIDrow] = useState("");
  const [tripTypeID, setTripTypeID] = useState("");

  const [routeIdError, setRouteIdError] = useState("");
  const [routeNameError, setRouteNameError] = useState("");
  const [tripDateError, setTripDateError] = useState("");
  const [vehicleTypeError, setVehicleTypeError] = useState("");
  const [shiftTimeError, setShiftTimeError] = useState("");
  const [tripTypeError, setTripTypeError] = useState("");

  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const [captchaValue, setCaptchaValue] = useState(null);

  useEffect(() => {
    setSelectedIds([]);
  }, [isLoading]);

  const isValid = () => {
    let isValid = true;

    if (!routeId) {
      setRouteIdError("Please enter route id");
      isValid = false;
    } else {
      setRouteIdError("");
    }
    if (!routeName) {
      setRouteNameError("Please enter route name");
      isValid = false;
    } else {
      setRouteNameError("");
    }
    if (!tripDate) {
      setTripDateError("Please select trip date");
      isValid = false;
    } else {
      setTripDateError("");
    }
    if (!shiftTime) {
      setShiftTimeError("Please select shift time");
      isValid = false;
    } else {
      setShiftTimeError("");
    }
    if (!tripType) {
      setTripTypeError("Please select trip type");
      isValid = false;
    } else {
      setTripTypeError("");
    }
    if (!vehicleType) {
      setVehicleTypeError("Please select vehicle type");
      isValid = false;
    } else {
      setVehicleTypeError("");
    }

    return isValid;
  };

  const handleCopyTrip = (row) => {
    setId(row.id);
    setRouteId(row.routeId);
    setRouteName(row.routeName);
    // setTripDate(DateUtils.convertDate(row.tripDate));
    setTripDate(row.tripDate);
    setVehicleType(row.vehicleType);
    setVehicleTypeID(row.vehicleTypeID);
    setShiftTime(row.shiftTime);
    setShiftTimeIDrow(row.shiftTimeID);
    setTripType(row.tripType);
    setTripTypeID(row.tripTypeID);
    setCopyModal(true);
  };

  const createTrip = async () => {
    if (!isValid()) {
      return;
    }
    try {
      const data = {
        id: id,
        routeId: routeId,
        routeName: routeName,
        tripDate: tripDate,
        shiftTime: shiftTime.id ? shiftTime.id : shiftTimeIDrow,
        tripType: tripType.id ? tripType.id : tripTypeID,
        vehicleType: vehicleType.id ? vehicleType.id : vehicleTypeID,
      };
      const response = await Trip.createCopyTrip(data);
      if (response.status === 200) {
        message.success("Trip created successfully.");
        fetchTripData();
        setId(null);
        setTripType("");
        setVehicleType("");
        setShiftTime("");
        setRouteId("");
        setRouteName("");
        setTripDate(null);
        setCopyModal(false);
      }
    } catch (error) {
      console.error("Error creating trip", error);
    }
  };

  const getAllocatedName = (id) => {
    if (id == 1) {
      return "app";
    } else if (id == 2) {
      return "wat";
    } else if (id == 3) {
      return "force";
    }
    return "notallocated";
  };

  const allocatedTypeList = [
    { id: 1, name: "App Assign" },
    { id: 2, name: "Wat Assign" },
    { id: 3, name: "Force Assign" },
    // { id: 4, name: "Not Assigned"},
  ];

  const columns = [
    {
      id: "routeId",
      name: <TableHead width="100px">Route ID</TableHead>,
      cell: (row) => (
        <TableCell width="100px">
          <Link
            className=" text-primary-blue"
            to={`/trip-management/${row.routeId}?id=${row.id}`}
          >
            {row.routeId}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "tripDate",
      name: <TableHead width="120px">Trip Date</TableHead>,
      cell: (row) => (
        <TableCell width="120px">
          {DateUtils.convertDate(row.tripDate)}
        </TableCell>
      ),
    },
    {
      id: "routeName",
      name: (
        <TableHead position="left" width="120px">
          Route Name
        </TableHead>
      ),
      cell: (row) => (
        <TableCell position="left" width="120px">
          {row.routeName}
        </TableCell>
      ),
    },
    {
      id: "allocatedType",
      name: <TableHead width="140px">Allocated Type</TableHead>,
      cell: (row) => (
        <TableCell width="140px">
          <a href={`tel:${row.driverNumber}`}>
            <StatusCard status={getAllocatedName(row.allocatedType)} />
          </a>
        </TableCell>
      ),
    },
    {
      id: "shiftTime",
      name: <TableHead width="120px">Shift Time</TableHead>,
      cell: (row) => (
        <TableCell width="120px" textCenter>
          {row.shiftTime}
        </TableCell>
      ),
    },
    {
      id: "tripType",
      name: <TableHead width="120px">Trip Type</TableHead>,
      cell: (row) => <TableCell width="120px">{row.tripType}</TableCell>,
    },

    {
      id: "vehicleType",
      name: (
        <TableHead width="120px" position="left">
          Vehicle Type
        </TableHead>
      ),
      cell: (row) => (
        <TableCell width="120px" position="left">
          {row.vehicleType}
        </TableCell>
      ),
    },
    {
      id: "numberOfEmployees",
      name: <TableHead width="140px">No. of Employees</TableHead>,
      cell: (row) => <TableCell width="140px">{row.noOfPickup}</TableCell>,
    },
    {
      id: "status",
      name: (
        <TableHead width="150px" position="center">
          Status
        </TableHead>
      ),
      cell: (row) => (
        <TableCell width="150px">
          <button
            type="button"
            onClick={() => {
              setStatusModal(true);
              setSelectedRow(row);
              handleNoOfEmp(row);
            }}
            disabled={row.tripStatus !== 0 && row.tripStatus !== 1}
          >
            <StatusCard status={row.tripStatus} />
          </button>
        </TableCell>
      ),
    },
    {
      id: "company",
      name: <TableHead width="90px">Duplicate</TableHead>,
      cell: (row) => (
        <TableCell width="90px">
          <button onClick={() => handleCopyTrip(row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5 9.16675C5 6.80972 5 5.63121 5.73223 4.89898C6.46447 4.16675 7.64297 4.16675 10 4.16675H12.5C14.857 4.16675 16.0355 4.16675 16.7677 4.89898C17.5 5.63121 17.5 6.80972 17.5 9.16675V13.3334C17.5 15.6904 17.5 16.8689 16.7677 17.6012C16.0355 18.3334 14.857 18.3334 12.5 18.3334H10C7.64297 18.3334 6.46447 18.3334 5.73223 17.6012C5 16.8689 5 15.6904 5 13.3334V9.16675Z"
                stroke="#667085"
                stroke-width="2"
              />
              <path
                d="M5 15.8334C3.61929 15.8334 2.5 14.7142 2.5 13.3334V8.33341C2.5 5.19071 2.5 3.61937 3.47631 2.64306C4.45262 1.66675 6.02397 1.66675 9.16667 1.66675H12.5C13.8807 1.66675 15 2.78604 15 4.16675"
                stroke="#667085"
                stroke-width="2"
              />
            </svg>
          </button>
        </TableCell>
      ),
    },
  ];

  const notAllottedColumns = [
    {
      id: "routeId",
      name: <TableHead width="100px">Route ID</TableHead>,
      cell: (row) => (
        <TableCell width="100px">
          <Link
            className=" text-primary-blue"
            to={`/trip-management/${row.routeId}?id=${row.id}`}
          >
            {row.routeId}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "tripDate",
      name: <TableHead width="120px">Trip Date</TableHead>,
      cell: (row) => (
        <TableCell width="120px">
          {DateUtils.convertDate(row.tripDate)}
        </TableCell>
      ),
    },
    {
      id: "routeName",
      name: (
        <TableHead position="left" width="120px">
          Route Name
        </TableHead>
      ),
      cell: (row) => (
        <TableCell position="left" width="120px">
          {row.routeName}
        </TableCell>
      ),
    },
    {
      id: "shiftTime",
      name: <TableHead width="120px">Shift Time</TableHead>,
      cell: (row) => (
        <TableCell width="120px" textCenter>
          {row.shiftTime}
        </TableCell>
      ),
    },
    {
      id: "tripType",
      name: <TableHead width="120px">Trip Type</TableHead>,
      cell: (row) => <TableCell width="120px">{row.tripType}</TableCell>,
    },

    {
      id: "vehicleType",
      name: (
        <TableHead width="120px" position="left">
          Vehicle Type
        </TableHead>
      ),
      cell: (row) => (
        <TableCell width="120px" position="left">
          {row.vehicleType}
        </TableCell>
      ),
    },
    {
      id: "numberOfEmployees",
      name: <TableHead width="140px">No. of Employees</TableHead>,
      cell: (row) => <TableCell width="140px">{row.noOfPickup}</TableCell>,
    },
    {
      id: "status",
      name: (
        <TableHead width="150px" position="center">
          Status
        </TableHead>
      ),
      cell: (row) => (
        <TableCell width="150px">
          <button
            type="button"
            onClick={() => {
              setStatusModal(true);
              setSelectedRow(row);
              handleNoOfEmp(row);
            }}
            disabled={row.tripStatus !== 0 && row.tripStatus !== 1}
          >
            <StatusCard status={row.tripStatus} />
          </button>
        </TableCell>
      ),
    },
    {
      id: "company",
      name: <TableHead width="90px">Duplicate</TableHead>,
      cell: (row) => (
        <TableCell width="90px">
          <button onClick={() => handleCopyTrip(row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5 9.16675C5 6.80972 5 5.63121 5.73223 4.89898C6.46447 4.16675 7.64297 4.16675 10 4.16675H12.5C14.857 4.16675 16.0355 4.16675 16.7677 4.89898C17.5 5.63121 17.5 6.80972 17.5 9.16675V13.3334C17.5 15.6904 17.5 16.8689 16.7677 17.6012C16.0355 18.3334 14.857 18.3334 12.5 18.3334H10C7.64297 18.3334 6.46447 18.3334 5.73223 17.6012C5 16.8689 5 15.6904 5 13.3334V9.16675Z"
                stroke="#667085"
                stroke-width="2"
              />
              <path
                d="M5 15.8334C3.61929 15.8334 2.5 14.7142 2.5 13.3334V8.33341C2.5 5.19071 2.5 3.61937 3.47631 2.64306C4.45262 1.66675 6.02397 1.66675 9.16667 1.66675H12.5C13.8807 1.66675 15 2.78604 15 4.16675"
                stroke="#667085"
                stroke-width="2"
              />
            </svg>
          </button>
        </TableCell>
      ),
    },
  ];

  const handleCheckboxChange = (e, row) => {
    if (row) {
      if (e.target.checked) {
        setSelectedIds((prev) => [...prev, row]);
      } else {
        setSelectedIds((prev) => prev.filter((item) => item !== row));
      }
      return;
    }
    if (selectAll) {
      setSelectedIds([]);
      setSelectAll(false);
    } else {
      if (selectedIds.length !== tripList.length) {
        setSelectedIds(tripList.map((item) => item));
      } else {
        setSelectedIds([]);
        setSelectAll(false);
      }
    }
    return;
  };

  const completedColumns = [
    {
      selector: "checkbox",
      headerCheckbox: true,
      id: "allCheckbox",
      name: (
        <TableHead width="40px">
          <div className="py-4 pl-1 text-[#3C4048] text-center w-full font-medium text-sm font-roboto hover:transform hover:scale-125 hover:font-base transition duration-300 ease-in-out">
            <span
              className={
                "py-4 text-center w-full text-sm font-roboto font-medium"
              }
            >
              <input
                type="checkbox"
                value={selectAll}
                className="cursor-pointer"
                onChange={(e) => handleCheckboxChange(e)}
                checked={selectAll || selectedIds.length === tripList.length}
              />
            </span>
          </div>
        </TableHead>
      ),
      cell: (row) => (
        <TableCell width="40px">
          <div className="py-4 text-[#3C4048] text-center font-medium text-sm font-roboto">
            <span
              className={"py-4 text-center text-sm font-roboto font-medium"}
            >
              <input
                type="checkbox"
                className="cursor-pointer"
                onChange={(e) => handleCheckboxChange(e, row)}
                checked={selectAll || selectedIds.includes(row)}
              />
            </span>
          </div>
        </TableCell>
      ),
    },
    {
      id: "routeId",
      name: <TableHead width="100px">Route ID</TableHead>,
      cell: (row) => (
        <TableCell width="100px">
          <Link
            className=" text-primary-blue"
            to={`/trip-management/${row.routeId}?id=${row.id}`}
          >
            {row.routeId}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "tripDate",
      name: <TableHead width="120px">Trip Date</TableHead>,
      cell: (row) => (
        <TableCell width="120px">
          {DateUtils.convertDate(row.tripDate)}
        </TableCell>
      ),
    },
    {
      id: "routeName",
      name: (
        <TableHead position="left" width="120px">
          Route Name
        </TableHead>
      ),
      cell: (row) => (
        <TableCell position="left" width="120px">
          {row.routeName}
        </TableCell>
      ),
    },
    {
      id: "allocatedType",
      name: <TableHead width="140px">Allocated Type</TableHead>,
      cell: (row) => (
        <TableCell width="140px">
          <a href={`tel:${row.driverNumber}`}>
            <StatusCard status={getAllocatedName(row.allocatedType)} />
          </a>
        </TableCell>
      ),
    },
    {
      id: "shiftTime",
      name: <TableHead width="120px">Shift Time</TableHead>,
      cell: (row) => (
        <TableCell width="120px" textCenter>
          {row.shiftTime}
        </TableCell>
      ),
    },
    {
      id: "tripType",
      name: <TableHead width="120px">Trip Type</TableHead>,
      cell: (row) => <TableCell width="120px">{row.tripType}</TableCell>,
    },

    {
      id: "vehicleType",
      name: (
        <TableHead width="120px" position="left">
          Vehicle Type
        </TableHead>
      ),
      cell: (row) => (
        <TableCell width="120px" position="left">
          {row.vehicleType}
        </TableCell>
      ),
    },
    {
      id: "numberOfEmployees",
      name: <TableHead width="140px">No. of Employees</TableHead>,
      cell: (row) => <TableCell width="140px">{row.noOfPickup}</TableCell>,
    },
    {
      id: "status",
      name: (
        <TableHead width="150px" position="center">
          Status
        </TableHead>
      ),
      cell: (row) => (
        <TableCell width="150px">
          <button
            type="button"
            onClick={() => {
              setStatusModal(true);
              setSelectedRow(row);
              handleNoOfEmp(row);
            }}
            disabled={row.tripStatus !== 0 && row.tripStatus !== 1}
          >
            <StatusCard status={row.tripStatus} />
          </button>
        </TableCell>
      ),
    },
    {
      id: "track",
      name: <TableHead width="140px">Track</TableHead>,
      cell: (row) => <TableCell width="140px">{row.track}</TableCell>,
    },
    {
      id: "company",
      name: <TableHead width="90px">Duplicate</TableHead>,
      cell: (row) => (
        <TableCell width="90px">
          <button onClick={() => handleCopyTrip(row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5 9.16675C5 6.80972 5 5.63121 5.73223 4.89898C6.46447 4.16675 7.64297 4.16675 10 4.16675H12.5C14.857 4.16675 16.0355 4.16675 16.7677 4.89898C17.5 5.63121 17.5 6.80972 17.5 9.16675V13.3334C17.5 15.6904 17.5 16.8689 16.7677 17.6012C16.0355 18.3334 14.857 18.3334 12.5 18.3334H10C7.64297 18.3334 6.46447 18.3334 5.73223 17.6012C5 16.8689 5 15.6904 5 13.3334V9.16675Z"
                stroke="#667085"
                stroke-width="2"
              />
              <path
                d="M5 15.8334C3.61929 15.8334 2.5 14.7142 2.5 13.3334V8.33341C2.5 5.19071 2.5 3.61937 3.47631 2.64306C4.45262 1.66675 6.02397 1.66675 9.16667 1.66675H12.5C13.8807 1.66675 15 2.78604 15 4.16675"
                stroke="#667085"
                stroke-width="2"
              />
            </svg>
          </button>
        </TableCell>
      ),
    },
  ];

  const columnsForStatus = [
    {
      id: "routeId",
      name: <TableHead>Employee ID</TableHead>,
      cell: (row) => <TableCell>{row.employee?.employeeID}</TableCell>,
    },
    {
      id: "empName",
      name: <TableHead>Employee Name</TableHead>,
      cell: (row) => (
        <TableCell textCenter>{row.employee?.employeeName}</TableCell>
      ),
    },
    {
      id: "pickup",
      name: <TableHead>Pickup Location</TableHead>,
      cell: (row) => <TableCell textCenter>{row.pickupLocation}</TableCell>,
    },
    {
      id: "attendence",
      name: <TableHead>Attendence</TableHead>,
      cell: (row, index) => (
        <TableCell textCenter>
          <label htmlFor="active" className="flex gap-2">
            <input
              type="checkbox"
              name="tripStatus"
              id="active"
              checked={noOfEmployees[index].picked === 3}
              onChange={(e) => handleCheckBox(e, index)}
            />
          </label>
        </TableCell>
      ),
    },
  ];

  const handleRevert = async () => {
    try {
      const data = {
        selectedIds: selectedIds?.map((item) => item.id),
      };
      const response = await Trip.revertCompletedTrip(data);
      if (response.status) {
        message.success("Trips(s) reverted successfully");
        setSelectedIds([]);
        fetchTripData();
        setConfirmModal(false);
      } else {
        message.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNoOfEmp = (row) => {
    const noOfEmployeesArray = row?.liveTrackingData?.map((item) => ({
      id: item.id,
      picked: 3,
    }));
    setNoOfEmployees(noOfEmployeesArray);
  };

  const handleCheckBox = (e, i) => {
    const noOfEmployeesArray = [...noOfEmployees];
    noOfEmployeesArray[i].picked = e.target.checked ? 3 : 4;
    setNoOfEmployees(noOfEmployeesArray);
  };

  const handleTripCompleted = async () => {
    if (!tripEndTime) {
      message.error("Please select Trip End Time.");
      return;
    }
    try {
      const data = {
        tripData: { id: selectedRow.id, tripEndTime: tripEndTime },
        liveTrackingData: noOfEmployees,
      };
      const response = await Trip.updateTripComplete(data);
      if (response.status === 200) {
        message.success("Trip completed successfully.");
        fetchTripData();
        setTripEndTime("");
        // setCaptchaValue(null);
        setStatusModal(false);
      } else {
        message.error(response.message);
      }
    } catch (err) {
      console.error("Error completing trip", err);
    }
  };

  const CopyModalContent = () => {
    return (
      <div className=" w-full  mt-6 mb-4">
        <div className=" w-full flex flex-col gap-3 lg:gap-6 py-10">
          <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
            <Input
              label={"Route ID"}
              placeholder={"Enter route id"}
              value={routeId}
              mandate={true}
              onChange={(e) => setRouteId(e.target.value)}
              error={routeIdError}
            />
            <Input
              label={"Route name"}
              placeholder={"Enter route name"}
              value={routeName}
              mandate={true}
              onChange={(e) => setRouteName(e.target.value)}
              error={routeNameError}
            />
            <DateInput
              label={"Trip date"}
              placeholder={"Select trip date"}
              value={tripDate}
              setValue={setTripDate}
              mandate={true}
              error={tripDateError}
            />
          </div>
          <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10 ">
            <DropDown
              label={"Shift time"}
              placeholder={shiftTime}
              displayValue={"name"}
              data={shiftTimeList}
              value={shiftTime}
              setValue={setShiftTime}
              mandate={true}
              error={shiftTimeError}
            />
            <DropDown
              label={"Trip type"}
              placeholder={tripType}
              displayValue={"name"}
              data={tripTypeList}
              value={tripType}
              setValue={setTripType}
              mandate={true}
              error={tripTypeError}
            />
            <DropDown
              label={"Vehicle type"}
              placeholder={vehicleType}
              displayValue={"name"}
              data={vehicleTypeData}
              value={vehicleType}
              setValue={setVehicleType}
              mandate={true}
              error={vehicleTypeError}
            />
          </div>
        </div>
        <div className=" w-full flex gap-5  justify-center items-center">
          <Button text={"Copy Trip"} width={"150px"} onClick={createTrip} />
        </div>
      </div>
    );
  };

  const StatusModalContent = () => {
    return (
      <div className=" w-full my-5">
        <div className=" w-full flex flex-col gap-3">
          <TimeInput
            label={"Trip End Time"}
            placeholder={"--:--"}
            value={tripEndTime}
            setValue={setTripEndTime}
            width="100px"
            mandate
          />
          <div className="flex flex-col max-w-[700px] overflow-y-auto gap-2">
            <style>
              {`
              .overflow-y-auto::-webkit-scrollbar {
                width: 9px;
              }
              .overflow-y-auto::-webkit-scrollbar-thumb {
                background-color: rgba(155, 155, 155);
                border-radius: 10px;
              }
              .overflow-y-auto::-webkit-scrollbar-track {
                background-color: rgba(155, 155, 155, 0.1);
              }
            `}
            </style>
            <div className={`flex flex-col mr-2 shadow-md shadow-gray-200`}>
              <div className="flex flex-col  overflow-auto z-0 border max-h-[400px] overflow-y-auto border-solid  rounded-t-lg rounded-b-lg  bg-tableHeaderGray border-tableBorderGray ">
                <table className="relative w-full ">
                  <thead className="w-full h-0 text-xs bg-[#EAECF0] font-medium leading-4 tracking-normal text-textGray font-inter">
                    <tr className="h-0">
                      {columnsForStatus?.map((column, index) => (
                        <th key={index}>{column.name}</th>
                      ))}
                    </tr>
                  </thead>
                </table>
                <div className="flex flex-col shadow-md bg-tableHeaderGray shadow-grey-200 ">
                  <table className="relative w-full h-full">
                    <tbody className="w-full bg-white ">
                      {selectedRow &&
                        selectedRow?.liveTrackingData?.map((row, index) => (
                          <tr key={index}>
                            {columnsForStatus?.map((column, i) => (
                              <td
                                key={i}
                                className="  text-textGray py-2 border-t-[1px]"
                              >
                                {column.cell(row, index)}
                              </td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <hr className="border-t-[1px] border-tableBorderGray" />
                </div>
              </div>
            </div>
            {/* <TableForModal columns={columnsForStatus} rows={tripList} /> */}
          </div>
        </div>
        <div className=" w-full flex flex-col gap-5  justify-center items-center mt-5">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={(value) => setCaptchaValue(value)}
          />
          {captchaValue && (
            <Button
              text={"Trip Completed"}
              width={"170px"}
              onClick={handleTripCompleted}
            />
          )}
        </div>
      </div>
    );
  };

  

  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-x-8 gap-2 w-full justify-center lg:flex-wrap  lg:pl-2 lg:justify-start  lg:pr-10 items-center mb-5 lg:mb-4  mt-2">
        {localStorage.getItem("userTypeFleetrax") !== "metayb" && localStorage.getItem("userTypeFleetrax") !== "botminds" && (
          <DropDown
            label={"Company"}
            placeholder={"Select company"}
            displayValue={"name"}
            data={companyList}
            value={companyID}
            setValue={setCompanyID}
            setLocalStorage={"tripCompanyID"}
            selectAll
          />
        )}
        <DropDown
          label={"Shift Time"}
          placeholder={"Select shift time"}
          displayValue={"name"}
          data={shiftTimeList}
          value={shiftTimeID}
          setValue={setShiftTimeID}
          setLocalStorage={"tripShiftTimeID"}
          selectAll
        />
        <DropDown
          label={"Trip Type"}
          placeholder={"Select trip type"}
          displayValue={"name"}
          data={tripTypeList}
          value={tripTypeFilter}
          setValue={setTripTypeFilter}
          setLocalStorage={"tripTripTypeID"}
          selectAll
        />
        <DropDown
          label={"Allocated Type"}
          placeholder={"Select allocated type"}
          displayValue={"name"}
          data={allocatedTypeList}
          value={allocatedTypeFilter}
          setValue={setAllocatedTypeFilter}
          setLocalStorage={"tripAllocatedTypeID"}
          selectAll
        />
        <DateInput
          label={"From date"}
          placeholder={"Select from date"}
          value={fromDate}
          setValue={setFromDate}
          setLocalStorage={"tripFromDate"}
        />
        <DateInput
          label={"To date"}
          placeholder={"Select to date"}
          value={toDate}
          setValue={setToDate}
          setLocalStorage={"tripToDate"}
        />
      </div>
      <div className="mb-3 w-full flex justify-end pr-2 gap-4">
        {activeKey === "allotted" || activeKey === "ongoing" || activeKey === "completed" ? (
          <DownloadButton tripList={tripList} />
          // <Button
          //   text={"Download"}
          //   width={"160px"}
          //   onClick={handleDownload}
          // />
        ) : null}

        {selectedIds.length > 0 && (
          <Button
            text={selectedIds.length > 1 ? "Revert Trips" : "Revert Trip"}
            width={"160px"}
            onClick={() => setConfirmModal(true)}
          />
        )}
      </div>
      {isLoading ? (
        <div className="animate-pulse rounded-md bg-[#EAECF0] w-full h-[60px] border"></div>
      ) : (
        <Table
          columns={
            activeKey === "completed"
              ? completedColumns
              : activeKey === "not-allotted"
              ? notAllottedColumns
              : columns
          }
          rows={tripList}
        />
      )}
      <Modal
        visible={copyModal}
        width={"800px"}
        onCancel={() => setCopyModal(false)}
        footer={null}
        centered
        className="my-6"
      >
        {CopyModalContent()}
      </Modal>
      <Modal
        title={
          selectedRow?.routeId +
          " / " +
          selectedRow?.routeName +
          " / " +
          selectedRow?.tripType +
          " / " +
          DateUtils.convertDate(selectedRow?.tripDate)
        }
        visible={statusModal}
        width={"630px"}
        onCancel={() => {
          setStatusModal(false);
          setTripEndTime(null);
          // setCaptchaValue(null);
        }}
        footer={null}
        centered
        className="my-6"
      >
        {StatusModalContent()}
      </Modal>
      <ConfirmModal
        isOpen={confirmModal}
        setIsOpen={setConfirmModal}
        title={"Revert ?"}
        description={"Do you want to revert selected trips?"}
        button1={"No"}
        button2={"Yes"}
        onClickButton1={() => setConfirmModal(false)}
        onClickButton2={handleRevert}
      />
    </div>
  );
}
