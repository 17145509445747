import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import { Typography } from "antd";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PageLayout from "../../components/layouts/PageLayout";
import RemittanceTable from "../../components/tables/remittance/RemittanceTable";

export default function Remittance() {
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  useEffect(() => {
    if (
      localStorage.getItem("userTypeFleetrax") == "supervisor" ||
      localStorage.getItem("userTypeFleetrax") == "admin" ||
      localStorage.getItem("userTypeFleetrax") == "lapiz" || 
      localStorage.getItem("userTypeFleetrax") == "metayb" ||
      localStorage.getItem("userTypeFleetrax") == "botminds"
    ) {
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  return (
    <>
      <PageLayout
        title={"Remittancee"}
        description={"View your remittance details here."}
        buttonWidth={"fit-content"}
        paddingRight={true}
        BreadcrumbsUrl={BreadcrumbsUrl}
      >
        <div className=" w-full flex mt-7 pr-4">
          <RemittanceTable />
        </div>
      </PageLayout>
    </>
  );
}
