import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import DateInput from "../../reusable/DateInput";
import eye from "./assets/eye.6bf84ddf18c60bb31dcf.png";
import ExcelJS from "exceljs";
import Reports from "../../../services/api/reportApi";
import Common from "../../../services/api/commonApi";
import DateUtils from "../../../services/utility/DateUtils";
import { Modal } from "antd";
import html2pdf from "html2pdf.js";
import { DownloadOutlined } from "@mui/icons-material";
import Button from "../../reusable/Button";
export default function AccountsTable() {
  const [shiftTime, setShiftTime] = useState("");

  const storedFromDate = localStorage.getItem("accountsFromDate");
  const initialFromDate = storedFromDate
    ? storedFromDate
    : DateUtils.getTMinusDate(6);

  const [fromDate, setFromDate] = useState(initialFromDate);

  const storedToDate = localStorage.getItem("accountsToDate");
  const initialToDate = storedToDate
    ? storedToDate
    : DateUtils.getCurrentDate();

  const [toDate, setToDate] = useState(initialToDate);

  const storedCompany = localStorage.getItem("accountsCompany");
  const initialCompany = storedCompany ? JSON.parse(storedCompany) : "";

  const [company, setCompany] = useState(initialCompany);

  const [shiftTimeList, setShiftTimeList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [accountsData, setAccountsData] = useState([]);
    const storedUser = localStorage.getItem("userTypeFleetrax");
    const initialUser = storedUser || "";
    const [userCompany, setUserCompany] = useState(initialUser);

  const navigate = useNavigate();

  window.onbeforeunload = () => {
    localStorage.removeItem("accountsFromDate");
    localStorage.removeItem("accountsToDate");
    localStorage.removeItem("accountsCompany");
  };

  useEffect(() => {
    fetchCompanyData();
    // fetchShiftTimeData();
  }, []);

  useEffect(() => {
    fetchAccountsData();
  }, [company, fromDate, shiftTime, toDate]);

  const handleAction = (id) => {
    navigate(`/report/accounts-details?id=${id}`);
  };

  const fetchAccountsData = async () => {
    try {
      const params = {
        companyId: userCompany === "lapiz" ? 3 : userCompany === "metayb" ? 7 : userCompany === "botminds" ? 8 : company?.id,
        fromDateAccount: fromDate,
        toDateAccount: toDate,
        // shiftTime: shiftTime
      };
      const data = await Reports.getTripsAccount(params);
      setAccountsData(data.data);
    } catch (error) {
      console.error("Error fetching reports data", error);
    }
  };
  const fetchCompanyData = async () => {
    try {
      const data = await Common.company();
      setCompanyList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchShiftTimeData = async () => {
    try {
      const data = await Common.shiftTime();
      setShiftTimeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const columns = [
    {
      id: "sNo",
      name: <TableHead width="100px">S.No</TableHead>,
      cell: (row) => <TableCell width="100px">{row.serialNumber}</TableCell>,
    },
    // {
    //   id: "tripDate",
    //   name: <TableHead>Trip Date</TableHead>,
    //   cell: (row) => <TableCell>{row.tripDate}</TableCell>,
    // },
    {
      id: "companyName",
      name: <TableHead position="left">Company Name</TableHead>,
      cell: (row) => <TableCell position="left">{row.companyName}</TableCell>,
    },
    // {
    //   id: "shiftTime",
    //   name: <TableHead>Shift Time</TableHead>,
    //   cell: (row) => <TableCell>{row.shiftTime}</TableCell>,
    // },
    {
      id: "paidBy",
      name: <TableHead position="left">Paid By</TableHead>,
      cell: (row) => <TableCell position="left">{row.invoicePaidBy}</TableCell>,
    },

    {
      id: "totalTrips",
      name: <TableHead>Total Trips</TableHead>,
      cell: (row) => (
        <TableCell>{row.totalCountOfInvoicePaidByTrips}</TableCell>
      ),
    },
    {
      id: "paidAmmount",
      name: <TableHead position="left">Paid Ammount</TableHead>,

      cell: (row) => (
        <TableCell position="left">
          ₹ {parseInt(row.sumOfInvoicePaidAmount)?.toLocaleString()}
        </TableCell>
      ),
    },
    {
      id: "company",
      name: <TableHead>Action</TableHead>,
      cell: (row) => (
        <TableCell>
          <button onClick={() => handleAction(row.invoicePaidById)}>
            <img width={35} src={eye} alt="eye" />
          </button>
        </TableCell>
      ),
    },
  ];

  let formattedFromDate = "N/A";
  let formattedToDate = "N/A";
  // useEffect(() => {
  //   if (fromDate && toDate) {
  //     const fromDate = new Date(fromDate);
  //     const toDate = new Date(toDate);

  //     // Format the dates as "dd/mm/yyyy"
  //     formattedFromDate = `${fromDate.getDate()}/${
  //       fromDate.getMonth() + 1
  //     }/${fromDate.getFullYear()}`;
  //     formattedToDate = `${toDate.getDate()}/${
  //       toDate.getMonth() + 1
  //     }/${toDate.getFullYear()}`;
  //   }
  // }, [fromDate, toDate]);

  const tableRows = accountsData.map((item) => {
    return `
        <tr>
          <td class="  border-solid border-black py-4 text-center">${item.serialNumber}</td>
          <td class=" border-solid border-black py-4 text-center">${item.companyName}</td>
          <td class=" border-solid border-black py-4 text-center">${item.invoicePaidBy}</td>
          <td class=" border-solid border-black py-4 text-center">${item.totalCountOfInvoicePaidByTrips}</td>
          <td class=" border-solid border-black py-4 text-center">${item.sumOfInvoicePaidAmount}</td>
        </tr>
      `;
  });

  const sumOfAmount = accountsData.reduce(
    (total, item) => total + item.sumOfInvoicePaidAmount,
    0
  );

  const sumOfTotalTrip = accountsData.reduce(
    (total, item) => total + item.totalCountOfInvoicePaidByTrips,
    0
  );
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState({});
  const htmlContent = `<!DOCTYPE html>
  <html lang="en">
  
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Document</title>
      <script src="https://cdn.tailwindcss.com"></script>
  
      <style>
@page {
size: A4;
margin: 0;
}

body {
font-family: Arial, sans-serif;
margin: 0;
padding: 0;
}

.container {
max-width: 1294px;
height: 1122px;
margin: 0 auto;
padding: 30px;
}

.logo img {
width: 75px;
}

.header {
display: flex;
align-items: center;
margin-bottom: 30px;
}

.header h1 {
font-size: 20px;
font-weight: bold;
margin-left: 10px;
}

.title {
text-align: center;
margin-bottom: 30px;
}

.title h1 {
font-size: 30px;
font-weight: bold;
margin-bottom: 10px;
}

.title hr {
background-color: #000;
height: 2px;
width: 270px;
margin: 0 auto;
border: none;
margin-bottom: 30px;
}

.info {
display: flex;
justify-content: space-between;
margin-bottom: 10px;
}

.info h5 {
font-size: 14px;
font-weight: bold;
}

.table-container {
margin-bottom: 30px;
}

table {
width: 100%;
border-collapse: collapse;
font-size: 14px;
}

th,
td {
border: 1px solid #000;
border-collapse: collapse;
padding: 10px;
text-align: center;
}

.total-row td:first-child {
border-left: none;
/* Remove the left border */
}



.signature {
display: flex;
justify-content: flex-end;
margin-top: 360px;
padding-right: 7px;
font-weight: bold;
}

</style>

  </head>
  
  <body class="w-1200">
      <div class="container w-1200">
          <div class="pl-6 py-4  w-full w-1200  justify-start items-start flex flex-col ">
             
              <img class="ml-2 " width="75px" src="https://chennaicabs.in/static/media/logo.c93104e337e8eb0719c6.png" alt="Logo">
  
              <h1 class="text-base font-semibold  ">Chennai Cabs</h1>
          </div>
  
  
          <div class="title">
              <h1>Accounts Summary</h1>
              <hr>
          </div>
  
          <div class="info">
              <div>
                  <h5>From Date: ${formattedFromDate}</h5>
              </div>
              <div>
                  <h5>To Date: ${formattedToDate}</h5>
              </div>
              <div>
                  <h5>Date: ${getCurrentDate()}</h5>
              </div>
          </div>
          <div class=" flex justify-center items-center bg-black h-[2px] w-full"></div>
          <div class="table-container  mt-10 w-1200">
              <table>
                  <thead>
                      <tr>
                          <th>S.No.</th>
                          <th>Project Name</th>
                          <th>Paid By</th>
                          <th>Total No of Trips</th>
                          <th>Amount</th>
                      </tr>
                  </thead>
                  <tbody>
                      ${tableRows.join("")}
                      <tr class="total-row">
                          <td colspan="2" class="border-none"></td>
                          <td class=" text-center font-bold">Total</td>
                          <td>${sumOfTotalTrip}</td>
                          <td>₹ ${sumOfAmount}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
  
          <div class="signature">
              <div>
                  Authorised Signature
              </div>
          </div>
      </div>
  </body>
  
  </html>`;
  const showPdf = () => {
    setShowPopup(true);

    setData(htmlContent);
  };
  const handleDownloadPDF = () => {
    const element = document.createElement("div");
    element.innerHTML = htmlContent;

    // Use html2pdf to convert the element
    html2pdf()
      .from(element)
      .set({
        margin: 10,
        filename: "document.pdf",
        html2canvas: { scale: 2 },
        jsPDF: { orientation: "portrait", unit: "mm", format: "a4" },
      })
      .save();
  };

  function getCurrentDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const headerTitle = (
    <div className=" justify-end items-center pr-10 w-full gap-5 flex  ">
      <div
        onClick={handleDownloadPDF}
        className=" flex items-center bg-[#2053AE] rounded-lg text-white  py-2 px-3 pr-10 gap-3"
      >
        Download
        <img
          width={20}
          src="https://dashboard.chennaicabs.in/static/media/download-pdf.b3d123a8332e31232231.png"
          alt=""
        />
      </div>
      <div className=" w-full pr-3">
        <div
          onClick={() => {
            setShowPopup(false);
          }}
          className=" flex justify-center items-center bg-gray-400 text-xl  rounded-full w-10 h-10"
        >
          {" "}
          X
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full removeScrollBar pb-5 text-base text-black">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-start items-center mb-5  lg:mb-5  mt-2 lg:mt-6">
        <DropDown
          label={"Company"}
          placeholder={"Select company"}
          displayValue={"name"}
          data={companyList}
          value={company}
          setValue={setCompany}
          setLocalStorage={"accountsCompany"}
          selectAll
        />
        {/* <DropDown
          label={"Shift Time"}
          placeholder={"Select shift time"}
          displayValue={"name"}
          data={shiftTimeList}
          value={shiftTime}
          setValue={setShiftTime}
        /> */}
        <DateInput
          label={"From date"}
          placeholder={"Select from date"}
          value={fromDate}
          setValue={setFromDate}
          setLocalStorage={"accountsFromDate"}
        />
        <DateInput
          label={"To date"}
          placeholder={"Select to date"}
          value={toDate}
          setValue={setToDate}
          setLocalStorage={"accountsToDate"}
        />
        <div className="flex justify-end w-[98.5%] pt-5 pr-5">
          <div className="flex">
            <button onClick={showPdf} className="flex  mb-5">
              <div className="bg-primary-blue/95 hover:bg-primary-blue flex justify-between items-center text-gray-100 text-xl font-semibold text-center w-full p-2 pl-4 pr-4 rounded-lg">
                <span className="mr-3">Download Now</span>
                <svg
                  className="text-2xl "
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
      <Table columns={columns} rows={accountsData} />
      <Modal
        visible={showPopup}
        onCancel={() => setShowPopup(false)}
        footer={null}
        width={1000}
        closeIcon={headerTitle}
      >
        <div className="w-full" dangerouslySetInnerHTML={{ __html: data }} />
      </Modal>
    </div>
  );
}
