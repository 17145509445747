import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import NotAllotedTable from "../tables/trip-management/NotAllotedTable";

// Function to render the label with active state
function LabelForTabs({ children, isActive }) {
  return (
    <div
      className={`${
        isActive ? "text-primary-blue" : "text-placeholder-gray"
      } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

// Main component for tab navigation
function TabNavigation({
  companyList,
  tripList,
  setTripList,
  setCompanyID,
  setShiftTimeID,
  setFromDate,
  setToDate,
  companyID,
  shiftTimeID,
  fromDate,
  toDate,
  tripStatus,
  setTripStatus,
  tripTypeID,
  setTripTypeID,
  shiftTimeList,
  setShiftTimeList,
  setRouteID,
  setTripAmount,
  vehicleTypeData,
  tripTypeList,
  fetchTripData,
  allocatedTypeFilter,
  setAllocatedTypeFilter,
  tripTypeFilter,
  setTripTypeFilter,
  isLoading,
  tripCount,
}) {
  const [activeKey, setActiveKey] = useState("not-allotted");
  
  useEffect(() => {
    const key = sessionStorage.getItem("tripTabNavigation");
    if (key) {
      setActiveKey(key);
    }
  }, []);

  useEffect(() => {
    if (activeKey === "not-allotted") {
      setTripStatus(0);
      setTripTypeID("");
    }
    if (activeKey === "allotted") {
      setTripStatus(1);
      setTripTypeID("");
    }
    if (activeKey === "ongoing") {
      setTripStatus(2);
      setTripTypeID("");
    }
    if (activeKey === "completed") {
      setTripStatus(3);
      setTripTypeID("");
    }
    if (activeKey === "pickup") {
      setTripTypeID(1);
      setTripStatus("");
    }
    if (activeKey === "drop") {
      setTripTypeID(2);
      setTripStatus("");
    }
    if (activeKey === "adhoc pickup") {
      setTripTypeID(3);
      setTripStatus("");
    }
    if (activeKey === "adhoc drop") {
      setTripTypeID(4);
      setTripStatus("");
    }
  }, [activeKey]);

  const onChange = (key) => {
    sessionStorage.setItem("tripTabNavigation", key);
    setActiveKey(key);
    if (key === "not-allotted") {
      setTripStatus(0);
      setTripTypeID("");
    }
    if (key === "allotted") {
      setTripStatus(1);
      setTripTypeID("");
    }
    if (key === "ongoing") {
      setTripStatus(2);
      setTripTypeID("");
    }
    if (key === "completed") {
      setTripStatus(3);
      setTripTypeID("");
    }
    if (key === "pickup") {
      setTripTypeID(1);
      setTripStatus("");
    }
    if (key === "drop") {
      setTripTypeID(2);
      setTripStatus("");
    }
    if (key === "adhoc pickup") {
      setTripTypeID(3);
      setTripStatus("");
    }
    if (key === "adhoc drop") {
      setTripTypeID(4);
      setTripStatus("");
    }
  };

  // Define the tab items with labels wrapped in LabelForTabs component
  const items = [
    {
      key: "not-allotted",
      label: (
        <LabelForTabs isActive={activeKey === "not-allotted"}>
          Not Allotted ({tripCount && tripCount.notAllocated})
        </LabelForTabs>
      ),
      children: (
        <NotAllotedTable
          setCompanyID={setCompanyID}
          setShiftTimeID={setShiftTimeID}
          setFromDate={setFromDate}
          setToDate={setToDate}
          companyID={companyID}
          shiftTimeID={shiftTimeID}
          fromDate={fromDate}
          toDate={toDate}
          companyList={companyList}
          shiftTimeList={shiftTimeList}
          tripList={tripList}
          setRouteID={setRouteID}
          vehicleTypeData={vehicleTypeData}
          tripTypeList={tripTypeList}
          fetchTripData={fetchTripData}
          allocatedTypeFilter={allocatedTypeFilter}
          setAllocatedTypeFilter={setAllocatedTypeFilter}
          tripTypeFilter={tripTypeFilter}
          setTripTypeFilter={setTripTypeFilter}
          activeKey={activeKey}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "allotted",
      label: (
        <LabelForTabs isActive={activeKey === "allotted"}>
          Allotted ({tripCount && tripCount.allocated})
        </LabelForTabs>
      ),
      children: (
        <NotAllotedTable
          setCompanyID={setCompanyID}
          setShiftTimeID={setShiftTimeID}
          setFromDate={setFromDate}
          setToDate={setToDate}
          companyID={companyID}
          shiftTimeID={shiftTimeID}
          fromDate={fromDate}
          toDate={toDate}
          companyList={companyList}
          shiftTimeList={shiftTimeList}
          tripList={tripList}
          setRouteID={setRouteID}
          vehicleTypeData={vehicleTypeData}
          tripTypeList={tripTypeList}
          fetchTripData={fetchTripData}
          allocatedTypeFilter={allocatedTypeFilter}
          setAllocatedTypeFilter={setAllocatedTypeFilter}
          tripTypeFilter={tripTypeFilter}
          setTripTypeFilter={setTripTypeFilter}
          activeKey={activeKey}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "ongoing",
      label: (
        <LabelForTabs isActive={activeKey === "ongoing"}>
          Ongoing
        </LabelForTabs>
      ),
      children: (
        <NotAllotedTable
          setCompanyID={setCompanyID}
          setShiftTimeID={setShiftTimeID}
          setFromDate={setFromDate}
          setToDate={setToDate}
          companyID={companyID}
          shiftTimeID={shiftTimeID}
          fromDate={fromDate}
          toDate={toDate}
          companyList={companyList}
          shiftTimeList={shiftTimeList}
          tripList={tripList}
          setRouteID={setRouteID}
          vehicleTypeData={vehicleTypeData}
          tripTypeList={tripTypeList}
          fetchTripData={fetchTripData}
          allocatedTypeFilter={allocatedTypeFilter}
          setAllocatedTypeFilter={setAllocatedTypeFilter}
          tripTypeFilter={tripTypeFilter}
          setTripTypeFilter={setTripTypeFilter}
          activeKey={activeKey}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "completed",
      label: (
        <LabelForTabs isActive={activeKey === "completed"}>
          Completed ({tripCount && tripCount.completed})
        </LabelForTabs>
      ),
      children: (
        <NotAllotedTable
          setCompanyID={setCompanyID}
          setShiftTimeID={setShiftTimeID}
          setFromDate={setFromDate}
          setToDate={setToDate}
          companyID={companyID}
          shiftTimeID={shiftTimeID}
          fromDate={fromDate}
          toDate={toDate}
          companyList={companyList}
          shiftTimeList={shiftTimeList}
          tripList={tripList}
          setRouteID={setRouteID}
          vehicleTypeData={vehicleTypeData}
          tripTypeList={tripTypeList}
          fetchTripData={fetchTripData}
          allocatedTypeFilter={allocatedTypeFilter}
          setAllocatedTypeFilter={setAllocatedTypeFilter}
          tripTypeFilter={tripTypeFilter}
          setTripTypeFilter={setTripTypeFilter}
          activeKey={activeKey}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "pickup",
      label: (
        <LabelForTabs isActive={activeKey === "pickup"}>Pickup ({tripCount && tripCount.pickup})</LabelForTabs>
      ),
      children: (
        <NotAllotedTable
          setCompanyID={setCompanyID}
          setShiftTimeID={setShiftTimeID}
          setFromDate={setFromDate}
          setToDate={setToDate}
          companyID={companyID}
          shiftTimeID={shiftTimeID}
          fromDate={fromDate}
          toDate={toDate}
          companyList={companyList}
          shiftTimeList={shiftTimeList}
          tripList={tripList}
          setRouteID={setRouteID}
          vehicleTypeData={vehicleTypeData}
          tripTypeList={tripTypeList}
          fetchTripData={fetchTripData}
          allocatedTypeFilter={allocatedTypeFilter}
          setAllocatedTypeFilter={setAllocatedTypeFilter}
          tripTypeFilter={tripTypeFilter}
          setTripTypeFilter={setTripTypeFilter}
          activeKey={activeKey}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "drop",
      label: <LabelForTabs isActive={activeKey === "drop"}>Drop ({tripCount && tripCount.drop})</LabelForTabs>,
      children: (
        <NotAllotedTable
          setCompanyID={setCompanyID}
          setShiftTimeID={setShiftTimeID}
          setFromDate={setFromDate}
          setToDate={setToDate}
          companyID={companyID}
          shiftTimeID={shiftTimeID}
          fromDate={fromDate}
          toDate={toDate}
          companyList={companyList}
          shiftTimeList={shiftTimeList}
          tripList={tripList}
          setRouteID={setRouteID}
          vehicleTypeData={vehicleTypeData}
          tripTypeList={tripTypeList}
          fetchTripData={fetchTripData}
          allocatedTypeFilter={allocatedTypeFilter}
          setAllocatedTypeFilter={setAllocatedTypeFilter}
          tripTypeFilter={tripTypeFilter}
          setTripTypeFilter={setTripTypeFilter}
          activeKey={activeKey}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "adhoc pickup",
      label: (
        <LabelForTabs isActive={activeKey === "adhoc pickup"}>
          Adhoc Pickup ({tripCount && tripCount.adhocPickup})
        </LabelForTabs>
      ),
      children: (
        <NotAllotedTable
          setCompanyID={setCompanyID}
          setShiftTimeID={setShiftTimeID}
          setFromDate={setFromDate}
          setToDate={setToDate}
          companyID={companyID}
          shiftTimeID={shiftTimeID}
          fromDate={fromDate}
          toDate={toDate}
          companyList={companyList}
          shiftTimeList={shiftTimeList}
          tripList={tripList}
          setRouteID={setRouteID}
          vehicleTypeData={vehicleTypeData}
          tripTypeList={tripTypeList}
          fetchTripData={fetchTripData}
          allocatedTypeFilter={allocatedTypeFilter}
          setAllocatedTypeFilter={setAllocatedTypeFilter}
          tripTypeFilter={tripTypeID}
          setTripTypeFilter={setTripTypeID}
          activeKey={activeKey}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "adhoc drop",
      label: (
        <LabelForTabs isActive={activeKey === "adhoc drop"}>
          Adhoc Drop ({tripCount && tripCount.adhocDrop})
        </LabelForTabs>
      ),
      children: (
        <NotAllotedTable
          setCompanyID={setCompanyID}
          setShiftTimeID={setShiftTimeID}
          setFromDate={setFromDate}
          setToDate={setToDate}
          companyID={companyID}
          shiftTimeID={shiftTimeID}
          fromDate={fromDate}
          toDate={toDate}
          companyList={companyList}
          shiftTimeList={shiftTimeList}
          tripList={tripList}
          setRouteID={setRouteID}
          vehicleTypeData={vehicleTypeData}
          tripTypeList={tripTypeList}
          fetchTripData={fetchTripData}
          allocatedTypeFilter={allocatedTypeFilter}
          setAllocatedTypeFilter={setAllocatedTypeFilter}
          tripTypeFilter={tripTypeFilter}
          setTripTypeFilter={setTripTypeFilter}
          activeKey={activeKey}
          isLoading={isLoading}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs activeKey={activeKey} items={items} onChange={onChange} />
    </div>
  );
}

export default TabNavigation;
