import { useEffect, useState } from "react";
import DateInput from "../../components/reusable/DateInput";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Table from "../../components/reusable/Table/Table";
import TableHead from "../../components/reusable/Table/TableHead";
import TableCell from "../../components/reusable/Table/TableCell";
import Passbook from "../../services/api/passbookApi";
import DateUtils from "../../services/utility/DateUtils";

export default function PassbookDetails({
  showDetailsPage,
  setShowDetailsPage,
}) {
  const [params] = useSearchParams();
  const paramsPayerID = params.get("id");
  const paramsPayerName = useParams().id;
  const paramsPayerDate = params.get("date");
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const [payerDetails, setPayerDetails] = useState([]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    if (
      localStorage.getItem("userTypeFleetrax") == "supervisor" ||
      localStorage.getItem("userTypeFleetrax") == "admin" ||
      localStorage.getItem("userTypeFleetrax") == "lapiz" ||
      localStorage.getItem("userTypeFleetrax") == "metayb" ||
      localStorage.getItem("userTypeFleetrax") == "botminds"
    ) {
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    if (!paramsPayerID) {
      setShowDetailsPage(false);
    }
  }, [location]);

  useEffect(() => {
    fetchPayerList();
  }, [fromDate, toDate]);

  const fetchPayerList = async () => {
    try {
      const params = {
        payerSelected: paramsPayerID,
        fromDate: fromDate || DateUtils.convertDateReverse(paramsPayerDate),
        toDate: toDate || DateUtils.convertDateReverse(paramsPayerDate),
      };
      const response = await Passbook.getBalanceData(params);
      setPayerDetails(response.data);
      setPayerDetails(
        response?.data?.length > 0 &&
          response?.data?.map((item, index) => {
            item.sNo = index + 1;
            return item;
          })
      );
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const columns = [
    {
      id: "srNo",
      name: <TableHead>S.No</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link className="text-primary-blue">{row.sNo}</Link>
        </TableCell>
      ),
    },
    {
      id: "date",
      name: <TableHead>Date</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.paidDate ? DateUtils.convertDate(row.paidDate) : "-"}
        </TableCell>
      ),
    },
    {
      id: "description",
      name: (
        <TableHead width="400px" position="left">
          Description
        </TableHead>
      ),
      cell: (row) => (
        <TableCell width="400px" position="left">
          {row.description}
        </TableCell>
      ),
    },
    {
      id: "transactionId",
      name: <TableHead>Transaction ID</TableHead>,
      cell: (row) => <TableCell>{row.transactionId}</TableCell>,
    },
    {
      id: "credit",
      name: <TableHead>Credit</TableHead>,
      cell: (row) => (
        <TableCell>
          <span className="text-green-500">
            ₹ {parseInt(row.paidAmount)?.toLocaleString()}
          </span>
        </TableCell>
      ),
    },
    {
      id: "debit",
      name: <TableHead>Debit</TableHead>,
      cell: (row) => (
        <TableCell>
          <span className="text-red-500">
            ₹ {parseInt(row.debitAmount)?.toLocaleString()}
          </span>
        </TableCell>
      ),
    },
    {
      id: "balance",
      name: <TableHead>Balance</TableHead>,
      cell: (row) => (
        <TableCell>₹ {parseInt(row.balance)?.toLocaleString()}</TableCell>
      ),
    },
  ];

  return (
    <>
      <div className=" w-full items-center flex h-full ">
        <button
          className="   ml-4  rounded-lg bg-[#cbcbcb] px-2 mt-5 py-1 w-10  flex items-center "
          onClick={() => {
            setShowDetailsPage(false);
            navigate("/passbook");
          }}
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="#fff"
              class="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
              />
            </svg>
          </div>
        </button>
        <div className="flex w-full h-full items-center flex-wrap justify-between px-10">
          <div className=" flex gap-4  whitespace-nowrap items-center">
            <div class="avatar w-auto h-auto bg-white rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                fill="#d5d5d5"
                class="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                />
              </svg>
            </div>
            <div className=" text-black text-2xl font-semibold">
              {paramsPayerName}
            </div>
          </div>
          <div className="flex gap-5 justify-start">
            <DateInput
              label={"From date"}
              placeholder={"Select from date"}
              value={fromDate || paramsPayerDate}
              setValue={setFromDate}
            />
            <DateInput
              label={"To date"}
              placeholder={"Select to date"}
              value={toDate || paramsPayerDate}
              setValue={setToDate}
            />
          </div>
          <button className="flex items-center pt-10 mb-5">
            <button
              type="button"
              className="bg-primary-blue flex justify-between items-center text-gray-100 text-xl font-semibold text-center w-full p-2 pl-4 pr-4 rounded-lg"
            >
              <span className="mr-3">Download Now</span>
              <svg
                className="text-2xl "
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-download"
                viewBox="0 0 16 16"
              >
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
              </svg>
            </button>
          </button>
        </div>
      </div>
      <div className="pt-6">
        <Table rows={payerDetails} columns={columns} />
      </div>
    </>
  );
}
