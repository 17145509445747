import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import logo from "../../assets/navbar/logo.svg";
import arrow_right from "../../assets/navbar/arrow_right.svg";
import arrow_left from "../../assets/navbar/arrow_left.svg";
import search from "../../assets/navbar/search.svg";
import settings from "../../assets/navbar/settings.svg";
import avatar from "../../assets/navbar/sample_avatar.svg";
import logout from "../../assets/navbar/logout.svg";

import home from "../../assets/navbar/home.svg";
import trip_management from "../../assets/navbar/trip_management.svg";
import driver_management from "../../assets/navbar/driver_management.svg";
import live_tracking from "../../assets/navbar/live_tracking.svg"
import employee_management from "../../assets/navbar/employee_management.svg";
import remittance from "../../assets/navbar/remittance.svg";
import ticket_management from "../../assets/navbar/ticket_management.svg";
import trip_request from "../../assets/navbar/trip_request.svg";
import passbook from "../../assets/navbar/passbook.svg";
import report from "../../assets/navbar/report.svg";
import expenses from "../../assets/navbar/expenses.svg";
import admin from "../../assets/navbar/admin.svg";
import invoice from "../../assets/navbar/invoice.svg";
import outstation from "../../assets/navbar/outstation.svg";
import routes from "../../assets/navbar/routes.svg";
import { message } from "antd";

export default function Navbar({ isOpen, setIsOpen, children }) {

  const path = useLocation().pathname;
  const userName = localStorage.getItem("nameFleetrax");
  const userEmail = localStorage.getItem("emailFleetrax");

  const LinkActive = (link) => {
    const linkCase = link.toLowerCase();
    const pathCase = path.toLowerCase();
    return pathCase.includes(linkCase);
  };

  const scrollBarCSS = `
        #scrollableNav::-webkit-scrollbar {
            width: 5px;
        }
        #scrollableNav::-webkit-scrollbar-thumb {
            background: #2E90FA;
            border-radius: 6px;
        }
        #labelSearch {
            position: relative;
        }
        #labelSearch:before {
            content: "";
            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            width: 16px;
            background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 16L12.375 12.375M14.3333 7.66667C14.3333 11.3486 11.3486 14.3333 7.66667 14.3333C3.98477 14.3333 1 11.3486 1 7.66667C1 3.98477 3.98477 1 7.66667 1C11.3486 1 14.3333 3.98477 14.3333 7.66667Z' stroke='gray' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") center / contain no-repeat;
        }
    `;

  const navContentForSuperAdminPlus = [
    {
      img: home,
      alt: "Home",
      title: "Home",
      link: "/home",
    },
    {
      img: trip_management,
      alt: "Trip Management",
      title: "Trip Management",
      link: "/trip-management",
    },
    {
      img: trip_request,
      alt: "Trip Request",
      title: "Trip Request",
      link: "/trip-request",
    },
    {
      img: live_tracking,
      alt: "Live Tracking",
      title: "Live Tracking",
      link: "/live-tracking",
    },
    {
      img: ticket_management,
      alt: "Ticket Management",
      title: "Ticket Management",
      link: "/ticket-management",
    },
    {
      img: employee_management,
      alt: "Employee Management",
      title: "Employee Management",
      link: "/employee-management",
    },
    {
      img: driver_management,
      alt: "Driver Management",
      title: "Driver Management",
      link: "/driver-management",
    },
    {
      img: report,
      alt: "Report",
      title: "Report",
      link: "/report",
    },
    {
      img: expenses,
      alt: "Expenses",
      title: "Expenses",
      link: "/expenses",
    },
    {
      img: passbook,
      alt: "Passbook",
      title: "Passbook",
      link: "/passbook",
    },
    {
      img: invoice,
      alt: "Invoice",
      title: "Invoice",
      link: "/invoice",
    },
    {
      img: outstation,
      alt: "Outstation",
      title: "Outstation",
      link: "/outstation",
    },
    {
      img: admin,
      alt: "Admin",
      title: "Admin",
      link: "/admin",
    },
    {
      img: remittance,
      alt: "Remittance",
      title: "Remittance",
      link: "/remittance",
    },
    {
      img: routes,
      alt: "Routes",
      title: "Routes",
      link: "/routes",
    },
  ];

  const navContentForSuperAdmin = [
    {
      img: home,
      alt: "Home",
      title: "Home",
      link: "/home",
    },
    {
      img: trip_management,
      alt: "Trip Management",
      title: "Trip Management",
      link: "/trip-management",
    },
    {
      img: trip_request,
      alt: "Trip Request",
      title: "Trip Request",
      link: "/trip-request",
    },
    {
      img: live_tracking,
      alt: "Live Tracking",
      title: "Live Tracking",
      link: "/live-tracking",
    },
    {
      img: ticket_management,
      alt: "Ticket Management",
      title: "Ticket Management",
      link: "/ticket-management",
    },
    {
      img: employee_management,
      alt: "Employee Management",
      title: "Employee Management",
      link: "/employee-management",
    },
    {
      img: driver_management,
      alt: "Driver Management",
      title: "Driver Management",
      link: "/driver-management",
    },
    {
      img: report,
      alt: "Report",
      title: "Report",
      link: "/report",
    },
    {
      img: expenses,
      alt: "Expenses",
      title: "Expenses",
      link: "/expenses",
    },
    {
      img: passbook,
      alt: "Passbook",
      title: "Passbook",
      link: "/passbook",
    },
    {
      img: invoice,
      alt: "Invoice",
      title: "Invoice",
      link: "/invoice",
    },
    {
      img: outstation,
      alt: "Outstation",
      title: "Outstation",
      link: "/outstation",
    },
    {
      img: remittance,
      alt: "Remittance",
      title: "Remittance",
      link: "/remittance",
    },
    {
      img: routes,
      alt: "Routes",
      title: "Routes",
      link: "/routes",
    },
  ];

  const navContentForAdmin = [
    {
      img: home,
      alt: "Home",
      title: "Home",
      link: "/home",
    },
    {
      img: trip_management,
      alt: "Trip Management",
      title: "Trip Management",
      link: "/trip-management",
    },
    {
      img: trip_request,
      alt: "Trip Request",
      title: "Trip Request",
      link: "/trip-request",
    },
    {
      img: live_tracking,
      alt: "Live Tracking",
      title: "Live Tracking",
      link: "/live-tracking",
    },
    {
      img: employee_management,
      alt: "Employee Management",
      title: "Employee Management",
      link: "/employee-management",
    },
    {
      img: driver_management,
      alt: "Driver Management",
      title: "Driver Management",
      link: "/driver-management",
    },
    {
      img: report,
      alt: "Report",
      title: "Report",
      link: "/report",
    },
    {
      img: outstation,
      alt: "Outstation",
      title: "Outstation",
      link: "/outstation",
    },
    {
      img: routes,
      alt: "Routes",
      title: "Routes",
      link: "/routes",
    },
  ];

  const navContentForSuperVisor = [
    {
      img: home,
      alt: "Home",
      title: "Home",
      link: "/home",
    },
    {
      img: trip_management,
      alt: "Trip Management",
      title: "Trip Management",
      link: "/trip-management",
    },
    {
      img: live_tracking,
      alt: "Live Tracking",
      title: "Live Tracking",
      link: "/live-tracking",
    },
    {
      img: employee_management,
      alt: "Employee Management",
      title: "Employee Management",
      link: "/employee-management",
    },
    {
      img: driver_management,
      alt: "Driver Management",
      title: "Driver Management",
      link: "/driver-management",
    },
  ];

  const navContentForCompanyUser = [
    {
      img: home,
      alt: "Home",
      title: "Home",
      link: "/home",
    },
    {
      img: trip_request,
      alt: "Trip Request",
      title: "Trip Request",
      link: "/trip-request",
    },
    {
      img: live_tracking,
      alt: "Live Tracking",
      title: "Live Tracking",
      link: "/live-tracking",
    },
    {
      img: employee_management,
      alt: "Employee Management",
      title: "Employee Management",
      link: "/employee-management",
    },
    {
      img: report,
      alt: "Report",
      title: "Report",
      link: "/report",
    },
    {
      img: routes,
      alt: "Routes",
      title: "Routes",
      link: "/routes",
    },
  ];

  const navContentForMetaybUser = [
    {
      img: home,
      alt: "Home",
      title: "Home",
      link: "/home",
    },
    {
      img: trip_management,
      alt: "Trip Management",
      title: "Trip Management",
      link: "/trip-management",
    },
    {
      img: trip_request,
      alt: "Trip Request",
      title: "Trip Request",
      link: "/trip-request",
    },
    {
      img: live_tracking,
      alt: "Live Tracking",
      title: "Live Tracking",
      link: "/live-tracking",
    },
    {
      img: employee_management,
      alt: "Employee Management",
      title: "Employee Management",
      link: "/employee-management",
    },
    {
      img: report,
      alt: "Report",
      title: "Report",
      link: "/report",
    },
    // {
    //   img: routes,
    //   alt: "Routes",
    //   title: "Routes",
    //   link: "/routes",
    // },
  ];

  const navContent = () => {
    const userType = localStorage.getItem("userTypeFleetrax");
    if (userType == 'supervisor') {
      return navContentForSuperVisor;
    }
    if (userType == 'admin') {
      return navContentForAdmin;
    }
    if (userType == 'superadmin') {
      return navContentForSuperAdmin;
    }
    if (userType == 'superAdminPlus') {
      return navContentForSuperAdminPlus;
    }
    if (userType == 'metayb' || userType == 'botminds') {
      return navContentForMetaybUser;
    }
    return navContentForCompanyUser;
  };

  const user = {
    avatar: avatar,
    name: userName,
    email: userEmail,
  };

  useEffect(() => {
    path !== "/" ? setIsOpen(true) : setIsOpen(false);
    // eslint-disable-next-line
  }, [path]);

  const handleSetIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    message.success("logged out successfully.");
    navigate("/login");
  };

  return (
    <>
      <style>{scrollBarCSS}</style>
      <div className="flex  w-full h-screen">
        <div
          className={`flex flex-col   justify-between h-full bg-primary-blue text-white ${!isOpen ? "items-center  w-[60px] lg:w-[80px] " : " w-[250px]"
            }`}
        >
          <div className="w-full">
            <div
              className={`h-[8vh] flex items-center justify-center gap-4 py-8 bg-[#2E90FA] ${!isOpen && "justify-center"
                }`}
            >
              <Link to="/">
                <img src={logo} alt="Logo" className="h-10" />
              </Link>
              {isOpen && <p className="text-3xl font-medium">FLEETRAX</p>}
            </div>
            <div
              id="scrollableNav"
              className={`h-[70vh] flex flex-col gap-[10px] px-2 pt-2 overflow-y-auto ${!isOpen && "items-center"
                }`}
            >
              <div
                className={`flex flex-col gap-3 w-full ${isOpen ? "items-end" : "items-center"
                  }`}
              >
                <button
                  onClick={handleSetIsOpen}
                  className={`flex items-center w-fit hover:bg-[#175CD3] px-1 py-1 rounded-md`}
                >
                  {isOpen ? (
                    <img src={arrow_left} alt="Contract" title="Close" />
                  ) : (
                    <img src={arrow_right} alt="Expand" title="Open" />
                  )}
                </button>
                {/* <button
                  className="flex justify-center w-full"
                  onClick={() => !isOpen && setIsOpen(true)}
                >
                  {isOpen ? (
                    <label
                      id="labelSearch"
                      htmlFor="search"
                      className="text-black w-full"
                    >
                      <input
                        name="search"
                        type="text"
                        className="py-1 pl-10 w-full rounded-md focus:outline-none placeholder:text-placeholder-gray"
                        placeholder={`Search`}
                      />
                    </label>
                  ) : (
                    <img
                      src={search}
                      alt="Search"
                      className="px-2 py-2 rounded-md hover:bg-[#175CD3]"
                      title="Search"
                    />
                  )}
                </button> */}
              </div>
              {navContent().map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className={`flex items-center gap-3 px-2 py-2 rounded-md hover:bg-[#175CD3] ${LinkActive(item.link) && "bg-[#175CD3]"}`}
                  title={!isOpen ? item.alt : ""}
                >
                  <img src={item.img} alt={item.alt} className="h-5" />
                  {isOpen && <p>{item.title}</p>}
                </Link>
              ))}
            </div>
          </div>
          <div
            className={`h-[22vh] flex flex-col justify-end pb-4 px-2 ${!isOpen ? "items-center gap-3" : "gap-2"
              }`}
          >
            <div className="flex flex-col gap-2">
              <Link
                to="/settings"
                className={`flex items-center gap-4 px-2 py-2 rounded-md hover:bg-[#175CD3] ${path === "/settings" && "bg-[#175CD3]"
                  }`}
              >
                <img
                  src={settings}
                  alt="Settings"
                  className="h-5 rounded-md hover:bg-[#175CD3]"
                  title="Settings"
                />
                {isOpen && <p>Settings</p>}
              </Link>
              <div className="bg-[#175CD3] h-[1.5px] w-full" />
            </div>
            <div className="flex justify-center gap-2">
              <img src={user.avatar} alt="Avatar" className={`h-10`} />
              {isOpen && (
                <div className="flex gap-1">
                  <div className="flex flex-col justify-center text-sm">
                    <p>{user.name}</p>
                    <p>{user.email}</p>
                  </div>

                  <button
                    onClick={handleLogout}
                    className="flex justify-start h-fit w-[35px]"
                    title="Logout"
                  >
                    <img src={logout} alt="Logout" className="-mt-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`
            ${isOpen ? "w-[calc(100%-250px)]" : "w-[calc(100%-60px)]  lg:w-[calc(100%-80px)]"}
        `}
        >
          {children}
        </div>
      </div>
    </>
  );
}
