import { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Typography, message } from "antd";
import { Card, Modal, Popover } from "antd";
import { Breadcrumbs } from "@mui/material";
import PageLayout from "../../components/layouts/PageLayout";
import addTrip from "../trip-management/assets/add-trip.png";
import driverImage from "../trip-management/assets/driver-image.png";
import vehicleImage from "../trip-management/assets/suv-image.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Input from "../../components/reusable/Input";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import TimeInput from "../../components/reusable/TimeInput";
import Button from "../../components/reusable/Button";
import Table from "../../components/reusable/Table/Table";
import TableCell from "../../components/reusable/Table/TableHead";
import TableHead from "../../components/reusable/Table/TableHead";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import edit from "../../assets/edit.svg";
import SuccessModal from "../../components/modals/SuccessModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import Employee from "../../services/api/employeeApi";
import Select from "react-select";
import Driver from "../../services/api/driverApi";
import Trip from "../../services/api/tripApi";
import Common from "../../services/api/commonApi";
import DateUtils from "../../services/utility/DateUtils";

export default function RoutesDetailPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const paramsTripID = params.get("id");

  const [trip, setTrip] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [tripTypesList, setTripTypesList] = useState([]);
  const [vehicleTypesList, setVehicleTypesList] = useState([]);
  const [shiftTimeList, setShiftTimeList] = useState([]);

  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const [assignDriverButtonId, setAssignDriverModalButtonId] = useState(0);
  const [assignDriverModal, setAssignDriverModal] = useState(false);
  const [editDriverModal, setEditDriverModal] = useState(false);
  const [editTripModal, setEditTripModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);

  const [tripEndTime, setTripEndTime] = useState(null);
  const [noOfEmployees, setNoOfEmployees] = useState(null);

  const [routeId, setRouteId] = useState("");
  const [company, setCompany] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [tripAmount, setTripAmount] = useState("");
  const [routeName, setRouteName] = useState("");
  const [tripDate, setTripDate] = useState("");
  const [shiftTime, setShiftTime] = useState("");
  const [tripType, setTripType] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [numberOfPickup, setNumberOfPickup] = useState("");
  const [addEmployee, setAddEmployee] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [pickupTimeList, setPickUpTimeList] = useState([]);
  const [pickupTimeAndRow, setPickupTimeAndRow] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [driver, setDriver] = useState("");
  const [numberOfPickupError, setNumberOfPickupError] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverMobileNumber, setDriverMobileNumber] = useState("");
  const [driverVehicleNumber, setDriverVehicleNumber] = useState("");
  const [driverVehicleType, setDriverVehicleType] = useState("");
  const [driverVehicleName, setDriverVehicleName] = useState("");
  const [driverStatus, setDriverStatus] = useState("");

  const [allocatedDriverID, setAllocatedDriverID] = useState("");
  const [allocatedDriverName, setAllocatedDriverName] = useState("");
  const [allocatedDriverMobileNo, setAllocatedDriverMobileNo] = useState("");
  const [allocatedDriverVehicleNo, setAllocatedDriverVehicleNo] = useState("");
  const [allocatedDriverVehicleType, setAllocatedDriverVehicleType] =
    useState("");
  const [allocatedDriverVehicleName, setAllocatedDriverVehicleName] =
    useState("");
  const [allocatedDriverStatus, setAllocatedDriverStatus] = useState("");
  const [allocatedDriverIDDisplay, setAllocatedDriverIDDisplay] = useState("");

  const [createdDate, setCreatedDate] = useState("");
  const [createdTime, setCreatedTime] = useState("");

  const [tripStatus, setTripStatus] = useState("");

  const [createdBy, setCreatedBy] = useState("");

  useEffect(() => {
    if (
      localStorage.getItem("userTypeFleetrax") != "superAdminPlus" &&
      localStorage.getItem("userTypeFleetrax") != "superadmin" &&
      localStorage.getItem("userTypeFleetrax") != "admin" &&
      localStorage.getItem("userTypeFleetrax") != "lapiz" &&
      localStorage.getItem("userTypeFleetrax") != "metayb" &&
      localStorage.getItem("userTypeFleetrax") != "botminds"
    ) {
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    fetchEmployeeData();
    // setSelectedEmployee([]);
  }, [company]);

  useEffect(() => {
    if (driver) {
      setDriverName(driver.driverName);
      setDriverMobileNumber(driver.mobileNo);
      setDriverVehicleNumber(driver.vehicleNo);
      setDriverVehicleType(driver.vehicleType.name);
      setDriverVehicleName(driver.vehicleName);
      setDriverStatus(driver.status);
    }
  }, [driver]);

  const fetchDriverData = async () => {
    try {
      const data = await Driver.getDrivers();
      setDriverList(data.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchEmployeeData = async () => {
    try {
      const dataObj = {
        companyId: company?.id ? company?.id : companyID,
      };
      const data = await Employee.findAllEmplyee(dataObj);
      setEmployeeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    setNumberOfPickup(selectedEmployee?.length);
    employeeData();
    if (selectedEmployee?.length > pickupTimeAndRow?.length) {
      const newEmp = selectedEmployee.filter(
        (employee) =>
          !pickupTimeAndRow?.map((item) => item?.id).includes(employee?.value)
      );
      const prev = [...pickupTimeAndRow];
      prev.push({ pickUpTime: null, id: newEmp[0]?.value });
      setPickupTimeAndRow(prev);
      return;
    }
    if (selectedEmployee.length < pickupTimeAndRow.length) {
      const missingEmp = pickupTimeAndRow?.filter(
        (employee) =>
          !selectedEmployee?.map((item) => item?.value)?.includes(employee?.id)
      );
      const prev = [...pickupTimeAndRow];
      const filtered = prev.filter((item) => item?.id !== missingEmp[0]?.id);
      setPickupTimeAndRow(filtered);
      return;
    }
  }, [selectedEmployee]);

  const handlePickupTime = (row, value) => {
    setPickupTimeAndRow((pickupTimeAndRow) => {
      const updatedVal = pickupTimeAndRow.map((item) => {
        if (item?.id === row?.id) {
          return { ...item, id: row?.id, pickUpTime: value };
        }
        return item;
      });
      if (!updatedVal.some((item) => item?.id === row?.id)) {
        updatedVal.push({ pickUpTime: value, id: row?.id });
      }
      return updatedVal;
    });
  };

  const updateTrip = async () => {
    try {
      const data = {
        id: paramsTripID,
        routeId: routeId,
        companyId: company.id,
        tripAmount: tripAmount,
        routeName: routeName,
        tripDate: tripDate,
        shiftTime: shiftTime.id,
        tripType: tripType.id,
        vehicleType: vehicleType.id,
        noOfPickup: numberOfPickup,
        liveTracking: pickupTimeAndRow.map((item) => {
          return { row: item.id, pickUpTime: item.pickUpTime };
        }),
        allocatedDriverID: driver.value,
        allocatedType: assignDriverButtonId,
        tripStatus: driver.value ? 1 : 0,
      };
      const response = await Trip.updateTrip(data);
      if (response.status) {
        message.success("Trip Updated successfully");
        setAssignDriverModal(false);
        setEditTripModal(false);
        fetchTripData();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Error updating data");
      console.error("Error fetching data", error);
    }
  };

  const assignTripDriver = async () => {
    try {
      const data = {
        id: paramsTripID,
        routeId: routeId,
        companyId: company.id ? company.id : companyID,
        tripAmount: tripAmount,
        routeName: routeName,
        tripDate: tripDate,
        shiftTime: shiftTime.id,
        tripType: tripType.id ? tripType.id : tripType,
        vehicleType: vehicleType.id ? vehicleType.id : vehicleType,
        noOfPickup: numberOfPickup,
        liveTracking: pickupTimeAndRow.map((item) => {
          return { row: item.id, pickUpTime: item.pickUpTime };
        }),
        allocatedDriverID: driver.value,
        allocatedType: assignDriverButtonId,
        tripStatus: driver.value ? 1 : 0,
      };
      let assignEndPoint;
      if (assignDriverButtonId === 1) {
        assignEndPoint = Trip.appAssign(data);
      } else if (assignDriverButtonId === 2) {
        assignEndPoint = Trip.watAssign(data);
      } else if (assignDriverButtonId === 3) {
        assignEndPoint = Trip.forceAssign(data);
      }
      const response = await assignEndPoint;
      if (response.status) {
        message.success("Driver assigned successfully");
        setAssignDriverModal(false);
        setEditTripModal(false);
        fetchTripData();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Error assigning driver");
      console.error("Error fetching data", error);
    }
  };

  const deleteTrip = async () => {
    try {
      const response = await Trip.deleteTrip({
        id: paramsTripID,
      });
      if (response.status === 200) {
        setConfirmModal(!confirmModal);
        setDeleteModal(!deleteModal);
        setTimeout(() => {
          setDeleteModal(!deleteModal);
          navigate("/trip-management");
        }, 2000);
      }
    } catch (error) {
      console.error("Error deleting data", error);
    }
  };

  const fetchTripData = async () => {
    try {
      const oneTrip = await Trip.findOneTrip({
        id: paramsTripID,
      });
      setTrip(oneTrip);
      setRouteId(oneTrip?.routeId);
      setCompany(oneTrip?.companyName);
      setCompanyID(oneTrip?.companyId);
      setTripAmount(oneTrip?.tripAmount);
      setRouteName(oneTrip?.routeName);
      setTripDate(oneTrip?.tripDate);
      setShiftTime(oneTrip?.shiftTime);
      setTripStatus(oneTrip?.tripStatus);
      setTripType(oneTrip?.tripType);
      setVehicleType(oneTrip?.vehicleType);
      setNumberOfPickup(oneTrip?.noOfPickup);
      setAllocatedDriverID(oneTrip?.allocatedDriverID);
      setAllocatedDriverName(oneTrip?.allocatedDriverName);
      setAllocatedDriverMobileNo(oneTrip?.allocatedDriverMobileNo);
      setAllocatedDriverVehicleNo(oneTrip?.allocatedDriverVehicleNo);
      setAllocatedDriverVehicleType(oneTrip?.allocatedDriverVehicleType);
      setAllocatedDriverVehicleName(oneTrip?.allocatedDriverVehicleName);
      setAllocatedDriverStatus(oneTrip?.allocatedDriverStatus);
      setAllocatedDriverIDDisplay(oneTrip?.allocatedDriverIDDisplay);
      setCreatedDate(DateUtils.convertDate(oneTrip?.createdAt));
      setCreatedTime(DateUtils.convertTime(oneTrip?.createdAt));
      setPickUpTimeList(
        oneTrip?.liveTracking.map((tracking) => ({
          id: tracking?.id,
          pickUpTime: tracking?.pickupTime,
          pickupLocation: tracking?.pickupLocation,
          dropLocation: tracking?.dropLocation,
        }))
      );
      setCreatedBy(oneTrip?.createdByName);

      // Set options based on employeeList
      const preOptions =
        oneTrip?.liveTracking?.map((employee) => ({
          value: employee.employeeID,
          label: employee.employee.employeeID,
        })) || [];

      setSelectedEmployee(preOptions);

      // Set pickup times in state
      const initialPickupTimes =
        oneTrip?.liveTracking?.map((tracking) => tracking.pickupTime) || [];
      const initialEmployeeID =
        oneTrip?.liveTracking?.map((tracking) => tracking.employeeID) || [];

      const sortedPickupTimes = initialPickupTimes
        .map((time, index) => ({
          pickUpTime: time,
          id: initialEmployeeID[index],
        }))
        .sort((a, b) => {
          const timeA = new Date(`1970-01-01T${a.pickUpTime}`);
          const timeB = new Date(`1970-01-01T${b.pickUpTime}`);
          return timeA - timeB;
        });
      setPickupTimeAndRow(sortedPickupTimes);
    } catch (error) {
      console.error("Error fetching trip data", error);
    }
  };

  useEffect(() => {
    fetchTripData();
    fetchCompanyData();
    fetchTripTypeData();
    fetchVehicleTypeData();
    fetchShiftTimeData();
    fetchDriverData();
  }, []);

  const fetchCompanyData = async () => {
    try {
      const allCompany = await Common.company();
      setCompanyList(allCompany);
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };

  const fetchShiftTimeData = async () => {
    try {
      const allShiftTime = await Common.shiftTime();
      setShiftTimeList(allShiftTime);
    } catch (error) {
      console.error("Error fetching shift-time data", error);
    }
  };

  const fetchTripTypeData = async () => {
    try {
      const allTripTypes = await Common.tripType();
      setTripTypesList(allTripTypes);
    } catch (error) {
      console.error("Error fetching trip-type data", error);
    }
  };

  const fetchVehicleTypeData = async () => {
    try {
      const allVehicleTypes = await Common.vehicleType();
      setVehicleTypesList(allVehicleTypes);
    } catch (error) {
      console.error("Error fetching vehicle-type data", error);
    }
  };

  const options = employeeList?.map((employee) => ({
    value: employee?.id,
    label: employee?.employeeID,
  }));

  const employeeData = () => {
    const extractedIds = selectedEmployee?.map((employee) => employee?.value);
    let newVals = [];
    let i = 0;
    for (i = 0; i < extractedIds?.length; i++) {
      if (filteredEmployees?.includes(extractedIds[i]) === false) {
        newVals.push(
          employeeList?.find((item) => item?.id === extractedIds[i])
        );
      }
    }
    setFilteredEmployees(
      newVals?.map((employee, index) => ({
        slNo: index + 1,
        id: employee?.id,
        employeeId: employee?.employeeID,
        employeeName: employee?.employeeName,
        gender: employee?.gender,
        contactNumber: employee?.mobileNumber,
        // pickUpTime: pickupTimeAndRow[index]?.pickUpTime || null,
        pickUpTime:
          pickupTimeAndRow?.find((item) => item?.id === employee?.id)
            ?.pickUpTime || null,
        ...(tripType === 1 || tripType === 3
          ? {
            pickupNodalPoint: employee?.pickUpLocation,
            dropLocation: employee?.dropLocation,
          }
          : {
            pickupNodalPoint: employee?.dropLocation,
            dropLocation: employee?.pickUpLocation,
          }),
      }))
    );
  };

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  const menuIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
      >
        <path
          d="M1.83333 8.5C2.29357 8.5 2.66667 8.1269 2.66667 7.66667C2.66667 7.20643 2.29357 6.83333 1.83333 6.83333C1.3731 6.83333 1 7.20643 1 7.66667C1 8.1269 1.3731 8.5 1.83333 8.5Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.83333 2.66667C2.29357 2.66667 2.66667 2.29357 2.66667 1.83333C2.66667 1.3731 2.29357 1 1.83333 1C1.3731 1 1 1.3731 1 1.83333C1 2.29357 1.3731 2.66667 1.83333 2.66667Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.83333 14.3333C2.29357 14.3333 2.66667 13.9602 2.66667 13.5C2.66667 13.0398 2.29357 12.6667 1.83333 12.6667C1.3731 12.6667 1 13.0398 1 13.5C1 13.9602 1.3731 14.3333 1.83333 14.3333Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  const employeeColumns = [
    {
      id: "slNo",
      name: <TableHead width="70px">SL.No</TableHead>,
      cell: (row) => <TableCell width="70px">{row?.srNo}</TableCell>,
    },
    {
      id: "employeeId",
      name: <TableHead>Employee Id</TableHead>,
      cell: (row) => (
        <TableCell centered>{row?.employee?.employeeID}</TableCell>
      ),
    },
    {
      id: "employeeName",
      name: <TableHead>Employee name</TableHead>,
      cell: (row) => (
        <TableCell centered>{row?.employee?.employeeName}</TableCell>
      ),
    },
    {
      id: "gender",
      name: <TableHead>Gender</TableHead>,
      cell: (row) => <TableCell> {row?.employee?.gender} </TableCell>,
    },
    {
      id: "contactNumber",
      name: <TableHead>Contact Number</TableHead>,
      cell: (row) => <TableCell> {row?.employee?.mobileNumber} </TableCell>,
    },
    {
      id: "pickupNodalPoint",
      name: <TableHead>Pickup Nodal Point</TableHead>,
      cell: (row) => (
        <TableCell>
          <div className="w-full flex justify-center items-center">
            <div className="w-full border-placeholder-gray rounded-lg">
              <input
                type="text"
                name="pickupNodalPoint"
                id="pickupNodalPoint"
                className={
                  "border  text-sm w-[120px] text-text-black placeholder-placeholder-gray outline-none rounded-lg pl-2 h-[40px] focus:border-[#1849A9]"
                }
                value={row?.pickupLocation}
                disabled
              />
            </div>
          </div>
        </TableCell>
      ),
    },
    {
      id: "pickupTime",
      name: <TableHead>Pickup Time</TableHead>,
      cell: (row) => (
        <TableCell>
          <div className="w-full flex justify-center items-center">
            <div className="border-placeholder-gray rounded-lg">
              <input
                type="time"
                name="pickupTime"
                id="pickupTime"
                className={
                  "border text-sm lg:text-ba w-[100px] text-text-black placeholder-placeholder-gray outline-none rounded-lg pl-2 h-[40px] focus:border-[#1849A9]"
                }
                value={row?.pickupTime}
                disabled
              />
            </div>
          </div>
        </TableCell>
      ),
    },
    {
      id: "dropLocation",
      name: <TableHead>Drop Location</TableHead>,
      cell: (row, index) => (
        <>
          <TableCell>
            <div className=" w-full  flex  justify-center items-center ">
              <div className="border-placeholder-gray rounded-lg">
                <input
                  type="text"
                  name="dropLocation"
                  id="dropLocation"
                  className={
                    "border  text-sm w-[110px] text-text-black placeholder-placeholder-gray outline-none rounded-lg pl-2 h-[40px] focus:border-[#1849A9]"
                  }
                  value={row?.dropLocation}
                  disabled
                />
              </div>
            </div>
          </TableCell>
        </>
      ),
    },
    {
      id: "contactNumber",
      name: <TableHead>OTP</TableHead>,
      cell: (row) => <TableCell> {row?.otp} </TableCell>,
    },
  ];

  const actionButton = (
    <button className="flex flex-col">
      {tripStatus != 2 && (
        <>
          <button
            className=" flex w-full items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
            onClick={() => {
              employeeData();
              setEditTripModal(!editTripModal);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
            >
              <path
                d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p>Edit</p>
          </button>
          <button
            className="flex items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
            onClick={() => setConfirmModal(!confirmModal)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
            >
              <path
                d="M2.09473 3.49984H3.28863M3.28863 3.49984H12.8399M3.28863 3.49984V11.6665C3.28863 11.9759 3.41442 12.2727 3.63832 12.4915C3.86222 12.7103 4.1659 12.8332 4.48254 12.8332H10.4521C10.7687 12.8332 11.0724 12.7103 11.2963 12.4915C11.5202 12.2727 11.646 11.9759 11.646 11.6665V3.49984H3.28863ZM5.0795 3.49984V2.33317C5.0795 2.02375 5.20528 1.72701 5.42918 1.50821C5.65308 1.28942 5.95676 1.1665 6.2734 1.1665H8.66122C8.97786 1.1665 9.28154 1.28942 9.50544 1.50821C9.72934 1.72701 9.85513 2.02375 9.85513 2.33317V3.49984M6.2734 6.4165V9.9165M8.66122 6.4165V9.9165"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p>Delete</p>
          </button>
        </>
      )}
      {tripStatus == 2 && (
        <button
          className="flex items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
          onClick={() => {
            setStatusModal(true);
            handleNoOfEmp();
          }}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 10L12.2581 12.4436C12.6766 12.7574 13.2662 12.6957 13.6107 12.3021L20 5"
              stroke="#33363F"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M21 12C21 13.8805 20.411 15.7137 19.3156 17.2423C18.2203 18.7709 16.6736 19.9179 14.893 20.5224C13.1123 21.1268 11.187 21.1583 9.38744 20.6125C7.58792 20.0666 6.00459 18.9707 4.85982 17.4789C3.71505 15.987 3.06635 14.174 3.00482 12.2945C2.94329 10.415 3.47203 8.56344 4.51677 6.99987C5.56152 5.4363 7.06979 4.23925 8.82975 3.57685C10.5897 2.91444 12.513 2.81996 14.3294 3.30667"
              stroke="#33363F"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
          <p>Complete Trip</p>
        </button>
      )}
    </button>
  );

  const actionButtonForDriverDetails = (
    <button className="flex flex-col">
      <button
        className=" flex w-full items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
        onClick={() => {
          setAssignDriverModalButtonId(1);
          setAssignDriverModal(!assignDriverModal);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>App Reassign</p>
      </button>
      <button
        className=" flex w-full items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
        onClick={() => {
          setAssignDriverModalButtonId(2);
          setAssignDriverModal(!assignDriverModal);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>Wat Reassign</p>
      </button>
      <button
        className=" flex w-full items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
        onClick={() => {
          setAssignDriverModalButtonId(3);
          setAssignDriverModal(!assignDriverModal);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>Force Reassign</p>
      </button>
      {/* <button
        className="flex items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
      // onClick={handleDelete}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M2.09473 3.49984H3.28863M3.28863 3.49984H12.8399M3.28863 3.49984V11.6665C3.28863 11.9759 3.41442 12.2727 3.63832 12.4915C3.86222 12.7103 4.1659 12.8332 4.48254 12.8332H10.4521C10.7687 12.8332 11.0724 12.7103 11.2963 12.4915C11.5202 12.2727 11.646 11.9759 11.646 11.6665V3.49984H3.28863ZM5.0795 3.49984V2.33317C5.0795 2.02375 5.20528 1.72701 5.42918 1.50821C5.65308 1.28942 5.95676 1.1665 6.2734 1.1665H8.66122C8.97786 1.1665 9.28154 1.28942 9.50544 1.50821C9.72934 1.72701 9.85513 2.02375 9.85513 2.33317V3.49984M6.2734 6.4165V9.9165M8.66122 6.4165V9.9165"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>Delete</p>
      </button> */}
    </button>
  );

  const TripDetails = () => {
    return (
      <>
        <div className=" w-full px-1 flex justify-center items-center ">
          <Card className=" w-full  mt-6  shadow-md  ">
            <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md">
              <div className=" w-full flex justify-between items-center">
                <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
                  Trip Details
                </div>
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10">
                <Input label={"Route ID"} value={trip?.routeId} readOnly />
                <Input label={"Company"} value={trip?.companyName} readOnly />
                <Input
                  label={"Trip amount"}
                  value={trip?.tripAmount}
                  readOnly
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
                <Input label={"Route name"} value={trip?.routeName} readOnly />
                <DateInput
                  label={"Trip date"}
                  value={trip?.tripDate}
                  readOnly
                />
                <TimeInput
                  label={"Shift time"}
                  value={trip?.shiftTime}
                  readOnly
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10 ">
                <Input label={"Trip type"} value={trip?.tripType} readOnly />
                <Input
                  label={"Vehicle type"}
                  value={trip?.vehicleType}
                  readOnly
                />
                <Input
                  label={"Number of pickup"}
                  value={trip?.noOfPickup}
                  readOnly
                />
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  };

  const EmployeeDetails = () => {
    return (
      <>
        <div className=" w-full px-1 flex pt-7 justify-center items-center ">
          <Card className=" w-full flex flex-col gap-6 pb-4  shadow-xl rounded-md">
            <div className=" w-full flex justify-between items-center">
              <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
                Employee Details
              </div>
            </div>
            <div className=" w-full flex flex-col gap-6 mt-6">
              <div className=" w-full">
                <Table
                  columns={employeeColumns}
                  rows={trip ? trip.liveTracking : [0]}
                  rowKey="id"
                  scroll={{ x: 800 }}
                  pagination={false}
                />
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  };

  const DriverDetails = () => {
    return (
      <div className=" w-full px-1 flex  py-7 justify-center items-center ">
        <Card className=" w-full flex flex-col gap-6 pb-4  shadow-xl rounded-md">
          {trip?.allocatedDriverID !== null ? (
            <>
              <div className=" w-full flex justify-between items-center">
                <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
                  Driver Details
                </div>
                {tripStatus !== 2 && tripStatus !== 3 && (
                  <Popover
                    content={actionButtonForDriverDetails}
                    placement="leftTop"
                    trigger="click"
                    className="cursor-pointer"
                  >
                    {menuIcon()}
                  </Popover>
                )}
              </div>
              <div className=" w-full flex mt-5">
                <div className=" w-full flex lg:flex-nowrap gap-5  flex-wrap justify-between items-center">
                  <div className=" w-fit flex justify-start items-center gap-5">
                    <div className=" w-fit hidden lg:block">
                      <img src={driverImage} alt="" />
                    </div>
                    <div className=" w-full flex">
                      <div className=" w-40 flex flex-col gap-4">
                        <div className="  w-full lg:w-40 flex ">
                          <div className=" w-32 text-sm font-semibold text-text-black ">
                            Driver name
                          </div>
                          <div className=" w-3 text-sm font-semibold text-text-black ">
                            :
                          </div>
                        </div>
                        <div className="  w-full lg:w-40 flex ">
                          <div className=" w-32 text-sm font-semibold text-text-black ">
                            Vehicle name
                          </div>
                          <div className=" w-3 text-sm font-semibold text-text-black ">
                            :
                          </div>
                        </div>
                        <div className="  w-full lg:w-40 flex ">
                          <div className=" w-32 text-sm font-semibold text-text-black ">
                            Mobile Number
                          </div>
                          <div className=" w-3 text-sm font-semibold text-text-black ">
                            :
                          </div>
                        </div>
                      </div>
                      <div className=" w-fit flex flex-col gap-4">
                        <div className=" w-fit flex ">
                          <div className=" lg:w-48 text-sm font-semibold text-text-black ">
                            {allocatedDriverName}
                          </div>
                        </div>
                        <div className=" w-fit flex ">
                          <div className=" lg:w-48 text-sm font-semibold text-text-black ">
                            {allocatedDriverVehicleName}
                          </div>
                        </div>
                        <div className=" w-fit flex ">
                          <div className=" lg:w-48 text-sm font-semibold text-text-black ">
                            {allocatedDriverMobileNo}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" w-fit flex justify-start items-center gap-5">
                    <div className=" w-fit hidden lg:block">
                      <img src={vehicleImage} alt="" />
                    </div>
                    <div className=" w-fit flex">
                      <div className=" w-40 flex flex-col gap-4">
                        <div className="  w-full lg:w-40 flex ">
                          <div className=" w-32 text-sm font-semibold text-text-black ">
                            Vehicle number
                          </div>
                          <div className=" w-3 text-sm font-semibold text-text-black ">
                            :
                          </div>
                        </div>
                        <div className="  w-full lg:w-40 flex ">
                          <div className=" w-32 text-sm font-semibold text-text-black ">
                            Status
                          </div>
                          <div className=" w-3 text-sm font-semibold text-text-black ">
                            :
                          </div>
                        </div>
                        <div className="  w-full lg:w-40 flex ">
                          <div className=" w-32 text-sm font-semibold text-text-black ">
                            Vehicle type
                          </div>
                          <div className=" w-3 text-sm font-semibold text-text-black ">
                            :
                          </div>
                        </div>
                      </div>
                      <div className=" w-fit flex flex-col gap-4">
                        <div className=" w-fit flex ">
                          <div className="  w-full lg:w-48 text-sm font-semibold text-text-black ">
                            {allocatedDriverVehicleNo}
                          </div>
                        </div>
                        <div className=" w-fit flex ">
                          <div className="  w-full lg:w-48 text-sm font-semibold text-text-black ">
                            {allocatedDriverStatus}
                          </div>
                        </div>
                        <div className=" w-fit flex ">
                          <div className="  w-full lg:w-48 text-sm font-semibold text-text-black ">
                            {allocatedDriverVehicleType}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {tripStatus !== 2 && tripStatus !== 3 ? (
                <div className=" w-full h-fit py-10 flex justify-center items-center flex-col gap-5">
                  <div className="text-black text-center font-roboto text-sm font-semibold leading-5">
                    Please assign driver for this trip !
                  </div>
                  <div className=" w-full flex flex-col lg:flex-row justify-center gap-6 items-center">
                    <Button
                      text={"App Assign"}
                      width={"170px"}
                      height={"40px"}
                      fontSize={"14px"}
                      borderRadius={"6px"}
                      color={"#1849A9"}
                      onClick={() => {
                        setAssignDriverModalButtonId(1);
                        setAssignDriverModal(!assignDriverModal);
                      }}
                    />
                    <Button
                      text={"Wat Assign"}
                      width={"170px"}
                      height={"40px"}
                      fontSize={"14px"}
                      borderRadius={"6px"}
                      color={"#1849A9"}
                      onClick={() => {
                        setAssignDriverModalButtonId(2);
                        setAssignDriverModal(!assignDriverModal);
                      }}
                    />
                    <Button
                      text={"Force Assign"}
                      width={"170px"}
                      height={"40px"}
                      fontSize={"14px"}
                      borderRadius={"6px"}
                      color={"#1849A9"}
                      onClick={() => {
                        setAssignDriverModalButtonId(3);
                        setAssignDriverModal(!assignDriverModal);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
                  Driver Details
                </div>
              )}
            </>
          )}
        </Card>
      </div>
    );
  };

  function checkStatus(status, name, time, pickupLocation) {
    const statusColor = () => {
      if (status === 0) return "#929695"; // gray
      if (status === 1) return "#f7ec0f"; // yellow
      if (status === 3) return "#2cf70c"; // green
      if (status === 4) return "#ed1607"; // red
    };
    const contentForPopOver = () => {
      return (
        <div className="flex flex-col justify-center items-center font-semibold">
          <p className="text-text-black">{name}</p>
          <p className="text-text-black">{pickupLocation}</p>
          <p className="text-[#175CD3]">{time}</p>
        </div>
      );
    };
    return (
      <Popover content={contentForPopOver}>
        <div className="flex justify-center items-center">
          <div
            className="w-3 h-3 rounded-full cursor-pointer"
            style={{ backgroundColor: statusColor() }}
          ></div>
        </div>
      </Popover>
    );
  }

  const statusDivWidth = 12;

  const LiveTrackingDetails = () => {
    return (
      <section
        className=" w-full px-1 flex  my-7 justify-center items-center"
        id="track-live"
      >
        <Card className=" w-full flex flex-col gap-6 pb-4  shadow-xl rounded-md">
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Live Tracking
          </div>
          <div className=" w-full flex justify-center items-center py-16">
            <div className="flex w-[600px] justify-center">
              {trip?.liveTracking?.map((item, index) => (
                <div className="flex items-center" key={index}>
                  {checkStatus(
                    item.picked,
                    item.employee.employeeName,
                    item.pickedTime ? item.pickedTime : item.pickupTime,
                    item.pickupLocation
                  )}
                  {index !== trip?.liveTracking.length - 1 && (
                    <div
                      style={{
                        width: `calc((600px - ${trip?.liveTracking.length * statusDivWidth
                          }px) / ${trip?.liveTracking.length - 1})`,
                      }}
                      className="h-[2px] bg-[#cbcdd2]"
                    ></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Card>
      </section>
    );
  };

  const CreateDetails = () => {
    return (
      <div className=" w-full px-1 flex  my-7 justify-center items-center ">
        <Card className=" w-full flex flex-col gap-6 pb-4  shadow-xl rounded-md">
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Creater Details
          </div>
          <>
            <div className=" w-full flex-wrap gap-5 h-fit py-10 flex justify-between items-center">
              <Input
                label={"Created by"}
                placeholder={"Enter created by"}
                value={createdBy || "-"}
                disabled={true}
              />
              <DateInput
                label={"Created date"}
                placeholder={"Select created date"}
                value={createdDate}
                disabled
              />
              <TimeInput
                label={"Created time"}
                placeholder={"Select created time"}
                value={createdTime}
                disabled
              />
            </div>
          </>
        </Card>
      </div>
    );
  };

  const driverOptions = driverList.map((driver) => ({
    value: driver.id,
    label: driver.driverID,
    driverID: driver.driverID,
    driverName: driver.driverName,
    mobileNo: driver.mobileNo,
    status: driver.driverStatus,
    vehicleName: driver.vehicleName,
    vehicleType: driver.vehicleType,
    vehicleNo: driver.vehicleNo,
  }));
  // assign driver modal
  const AssignDriverModal = () => {
    return (
      <div className=" w-full  mt-6 mb-10  ">
        <div className=" w-full text-start text-base mt-1 font-semibold text-gray-800 ">
          {allocatedDriverID ? "Reassign Driver" : "Assign Driver"}
        </div>
        <div className=" w-full flex flex-col  gap-4 lg:gap-9  pt-10">
          <div className=" w-full flex flex-wrap lg:flex-nowrap  justify-center lg:justify-start items-center  gap-3  lg:gap-10 ">
            <div>
              <div className="text-[#344054] text-[15px] pl-1 font-medium">
                Driver ID <span className="text-[#D92D20]">*</span>
              </div>
              <Select
                classNamePrefix="Select driver ID"
                className="basic-multi-select w-[290px] rounded-md !border-border-gray"
                isSearchable={true}
                options={driverOptions}
                value={
                  driver || {
                    label: allocatedDriverIDDisplay,
                    value: allocatedDriverIDDisplay,
                  }
                }
                onChange={(option) => setDriver(option)}
              />
            </div>
          </div>
          <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3  lg:gap-10  ">
            <Input
              label={"Driver Name"}
              placeholder={"-"}
              value={driver ? driverName : allocatedDriverName}
              disabled={true}
            />
            <MobileNumberInput
              label={"Mobile Number"}
              placeholder={"-"}
              value={driver ? driverMobileNumber : allocatedDriverMobileNo}
              disabled={true}
            />
            <Input
              label={"Status"}
              placeholder={"-"}
              value={driver ? driverStatus : allocatedDriverStatus}
              disabled={true}
            />
          </div>
          <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3  lg:gap-10  ">
            <Input
              label={"Vehicle Name"}
              placeholder={"-"}
              value={driver ? driverVehicleName : allocatedDriverVehicleName}
              disabled={true}
            />
            <Input
              label={"Vehicle Type"}
              placeholder={"-"}
              value={driver ? driverVehicleType : allocatedDriverVehicleType}
              disabled={true}
            />
            <Input
              label={"Vehicle Number"}
              placeholder={"-"}
              value={driver ? driverVehicleNumber : allocatedDriverVehicleNo}
              disabled={true}
            />
          </div>
          <div className=" w-full flex mt-4  justify-center items-center">
            <Button
              text={"Assign"}
              width={"170px"}
              onClick={() => {
                assignTripDriver();
                setTimeout(() => {
                  fetchTripData();
                }, 1000);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  // below are for edit modals
  const employeeColumnsForEdit = [
    {
      id: "slNo",
      name: <TableHead width="70px">SL.No</TableHead>,
      cell: (row) => <TableCell width="70px">{row.slNo}</TableCell>,
    },
    {
      id: "employeeId",
      name: <TableHead>Employee Id</TableHead>,
      cell: (row) => <TableCell centered>{row.employeeId}</TableCell>,
    },
    {
      id: "employeeName",
      name: <TableHead>Employee name</TableHead>,
      cell: (row) => <TableCell centered>{row.employeeName}</TableCell>,
    },
    {
      id: "gender",
      name: <TableHead>Gender</TableHead>,
      cell: (row) => <TableCell> {row.gender} </TableCell>,
    },
    {
      id: "contactNumber",
      name: <TableHead>Contact Number</TableHead>,
      cell: (row) => <TableCell> {row.contactNumber} </TableCell>,
    },
    {
      id: "pickupNodalPoint",
      name: <TableHead>Pickup Nodal Point</TableHead>,
      cell: (row) => (
        <TableCell>
          <div className="w-full flex justify-center items-center">
            <div className="w-full border-placeholder-gray rounded-lg">
              <input
                type="text"
                name="pickupNodalPoint"
                id="pickupNodalPoint"
                className={
                  "border  text-sm w-[120px] text-text-black placeholder-placeholder-gray outline-none rounded-lg pl-2 h-[40px] focus:border-[#1849A9]"
                }
                placeholder={"Pickup Nodal Point"}
                disabled={true}
                value={row.pickupNodalPoint}
              />
            </div>
          </div>
        </TableCell>
      ),
    },
    {
      id: "pickupTime",
      name: <TableHead>Pickup Time</TableHead>,
      cell: (row, index) => (
        <TableCell>
          <div className="w-full flex justify-center items-center">
            <div className=" border-placeholder-gray rounded-lg">
              <input
                type="time"
                name="pickupTime"
                id="pickupTime"
                className={
                  "border text-sm lg:text-ba w-[100px] text-text-black placeholder-placeholder-gray outline-none rounded-lg pl-2 h-[40px] focus:border-[#1849A9]"
                }
                value={
                  pickupTimeAndRow.find((item) => item.id === row.id)
                    ?.pickUpTime || null
                }
                onChange={(e) => handlePickupTime(row, e.target.value, index)}
              />
            </div>
          </div>
        </TableCell>
      ),
    },
    {
      id: "dropLocation",
      name: <TableHead>Drop Location</TableHead>,
      cell: (row) => (
        <TableCell>
          <div className=" w-full flex justify-center items-center ">
            <div className="border-placeholder-gray rounded-lg">
              <input
                type="text"
                name="dropLocation"
                id="dropLocation"
                className={
                  "border  text-sm w-[110px] text-text-black placeholder-placeholder-gray outline-none rounded-lg pl-2 h-[40px] focus:border-[#1849A9]"
                }
                placeholder={"Enter Drop Location"}
                value={row.dropLocation}
                disabled={true}
              />
            </div>
          </div>
        </TableCell>
      ),
    },
  ];

  const handleSubmit = () => {
    let isValid = true;

    if (!numberOfPickup || numberOfPickup === 0) {
      setNumberOfPickupError("Atlest one pickup is required");
      isValid = false;
    } else {
      setNumberOfPickupError("");
    }
    if (isValid) {
      const checkForNull = pickupTimeAndRow.map((item) => item.pickUpTime);
      if (
        pickupTimeAndRow.length === selectedEmployee.length &&
        checkForNull.includes(null) === false
      ) {
        updateTrip();
      } else {
        message.error("Please enter pickup time for all employees");
      }
    }
  };

  const TripDetailsModal = () => {
    return (
      <>
        <div className=" w-full  mt-6 mb-4">
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Edit Trip
          </div>
          <div className=" w-full flex flex-col  gap-3 lg:gap-6 py-6">
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
              <Input
                label={"Route ID"}
                placeholder={"Enter route id"}
                value={routeId}
                onChange={(e) => setRouteId(e.target.value)}
                mandate={true}
              />
              <DropDown
                label={"Company"}
                placeholder={company}
                displayValue={"name"}
                data={companyList}
                value={company}
                setValue={setCompany}
                onChange={(selectedItem) => {
                  setSelectedEmployee([]);
                }}
              />
              <Input
                label={"Trip amount"}
                placeholder={"Enter trip amount"}
                value={tripAmount}
                onChange={(e) => setTripAmount(e.target.value)}
                mandate={true}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10 ">
              <Input
                label={"Route name"}
                placeholder={"Enter route name"}
                value={routeName}
                onChange={(e) => setRouteName(e.target.value)}
                mandate={true}
              />
              <DateInput
                label={"Trip date"}
                placeholder={"Select trip date"}
                value={tripDate}
                setValue={setTripDate}
                mandate={true}
              />
              <DropDown
                label={"Shift Time"}
                placeholder={"Select shift time"}
                displayValue={"name"}
                data={shiftTimeList}
                defaultValue={shiftTime}
                value={shiftTime}
                setValue={setShiftTime}
                mandate={true}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10 ">
              <DropDown
                label={"Trip type"}
                placeholder={"Select trip type"}
                displayValue={"name"}
                data={tripTypesList}
                defaultValue={tripType}
                value={tripType}
                setValue={setTripType}
                mandate={true}
              />
              <DropDown
                label={"Vehicle type"}
                placeholder={"Select vehicle type"}
                displayValue={"name"}
                data={vehicleTypesList}
                defaultValue={vehicleType}
                value={vehicleType}
                setValue={setVehicleType}
                mandate={true}
              />
              <Input
                label={"Number of pickup"}
                placeholder={"No of pickup"}
                value={numberOfPickup}
                setValue={setNumberOfPickup}
                mandate={true}
                onChange={(e) => setNumberOfPickup(e.target.value)}
                disabled={true}
              // error={numberOfPickupError}
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-6 pb-10">
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-start items-center gap-3 lg:gap-10">
              <div className="max-w-[800px]">
                <label className="text-[#344054] text-[15px] pl-1 font-medium">
                  Select Employee
                </label>
                <Select
                  isMulti
                  name="colors"
                  options={options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    setSelectedEmployee(selectedOptions)
                  }
                  value={selectedEmployee}
                />
              </div>
              <div className="lg:w-1/2"></div>
            </div>
            <div className="w-full">
              <Table
                columns={employeeColumnsForEdit}
                rows={filteredEmployees}
                rowKey="id"
                scroll={{ x: 800 }}
                pagination={false}
              />
            </div>
          </div>
          <div className=" w-full flex gap-5  justify-center items-center">
            <Button text={"Submit"} width={"170px"} onClick={handleSubmit} />
          </div>
        </div>
      </>
    );
  };

  const handleNoOfEmp = () => {
    const noOfEmployeesArray = trip?.liveTracking?.map((item) => ({
      id: item?.id,
      picked: 3,
    }));
    setNoOfEmployees(noOfEmployeesArray);
  };

  const handleCheckBox = (e, i) => {
    const noOfEmployeesArray = [...noOfEmployees];
    noOfEmployeesArray[i].picked = e.target.checked ? 3 : 4;
    setNoOfEmployees(noOfEmployeesArray);
  };

  const columnsForStatus = [
    {
      id: "routeId",
      name: <TableHead>Employee ID</TableHead>,
      cell: (row) => <TableCell>{row?.employee?.employeeID}</TableCell>,
    },
    {
      id: "empName",
      name: <TableHead>Employee Name</TableHead>,
      cell: (row) => (
        <TableCell textCenter>{row?.employee?.employeeName}</TableCell>
      ),
    },
    {
      id: "pickup",
      name: <TableHead>Pickup Location</TableHead>,
      cell: (row) => <TableCell textCenter>{row?.pickupLocation}</TableCell>,
    },
    {
      id: "attendence",
      name: <TableHead>Attendence</TableHead>,
      cell: (row, index) => (
        <TableCell textCenter>
          {noOfEmployees && (
            <label htmlFor="active" className="flex gap-2">
              <input
                type="checkbox"
                name="tripStatus"
                id="active"
                checked={noOfEmployees[index].picked === 3}
                onChange={(e) => handleCheckBox(e, index)}
              />
            </label>
          )}
        </TableCell>
      ),
    },
  ];

  const handleTripCompleted = async () => {
    if (!tripEndTime) {
      message.error("Please select Trip End Time.");
      return;
    }
    try {
      const data = {
        tripData: { id: trip.id, tripEndTime: tripEndTime },
        liveTrackingData: noOfEmployees,
      };
      const response = await Trip.updateTripComplete(data);
      if (response.status === 200) {
        message.success("Trip completed successfully.");
        fetchTripData();
        setStatusModal(false);
      } else {
        message.error(response.message);
      }
    } catch (err) {
      console.error("Error completing trip", err);
    }
  };

  const StatusModalContent = () => {
    return (
      <div className=" w-full my-5">
        <div className=" w-full flex flex-col gap-3">
          <TimeInput
            label={"Trip End Time"}
            placeholder={"--:--"}
            value={tripEndTime}
            setValue={setTripEndTime}
            width="100px"
            mandate
          />
          <div className="flex flex-col max-w-[700px] overflow-y-auto gap-2">
            <style>
              {`
              .overflow-y-auto::-webkit-scrollbar {
                width: 9px;
              }
              .overflow-y-auto::-webkit-scrollbar-thumb {
                background-color: rgba(155, 155, 155);
                border-radius: 10px;
              }
              .overflow-y-auto::-webkit-scrollbar-track {
                background-color: rgba(155, 155, 155, 0.1);
              }
            `}
            </style>
            <div className={`flex flex-col mr-2 shadow-md shadow-gray-200`}>
              <div className="flex flex-col  overflow-auto z-0 border max-h-[400px] overflow-y-auto border-solid  rounded-t-lg rounded-b-lg  bg-tableHeaderGray border-tableBorderGray ">
                <table className="relative w-full ">
                  <thead className="w-full h-0 text-xs bg-[#EAECF0] font-medium leading-4 tracking-normal text-textGray font-inter">
                    <tr className="h-0">
                      {columnsForStatus &&
                        columnsForStatus?.map((column, index) => (
                          <th key={index}>{column?.name}</th>
                        ))}
                    </tr>
                  </thead>
                </table>
                <div className="flex flex-col shadow-md bg-tableHeaderGray shadow-grey-200 ">
                  <table className="relative w-full h-full">
                    <tbody className="w-full bg-white ">
                      {trip &&
                        trip?.liveTracking?.map((row, index) => (
                          <tr key={index}>
                            {columnsForStatus?.map((column, i) => (
                              <td
                                key={i}
                                className="  text-textGray py-2 border-t-[1px]"
                              >
                                {column.cell(row, index)}
                              </td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <hr className="border-t-[1px] border-tableBorderGray" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full flex gap-5  justify-center items-center mt-5">
          <Button
            text={"Trip Completed"}
            width={"170px"}
            onClick={handleTripCompleted}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <PageLayout
        title={"Trip Management"}
        description={"View your trip details here."}
        buttonWidth={"120px"}
        BreadcrumbsUrl={BreadcrumbsUrl}
      >
        {tripStatus !== 3 && (
          <div className="absolute right-16 top-20">
            <Popover
              content={actionButton}
              placement="leftTop"
              trigger="click"
              className="cursor-pointer"
            >
              {menuIcon()}
            </Popover>
          </div>
        )}

        {TripDetails()}
        {EmployeeDetails()}
        {DriverDetails()}
        {LiveTrackingDetails()}
        {CreateDetails()}
      </PageLayout>
      {/* assign driver modal */}
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="add" />
          </>
        }
        visible={assignDriverModal}
        width={"1000px"}
        onCancel={() => setAssignDriverModal(false)}
        footer={null}
      >
        {AssignDriverModal()}
      </Modal>
      {/* edit driver modal */}
      {/* <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="add" />
          </>
        }
        visible={editDriverModal}
        width={"1000px"}
        onCancel={() => setAssignDriverModal(false)}
        footer={null}
      >
        {AssignDriverModal()}
      </Modal> */}
      {/* trip-details modal */}
      <Modal
        title={
          <>
            <img src={edit} width={40} alt="edit" />
          </>
        }
        visible={editTripModal}
        width={"1000px"}
        className="my-10"
        onCancel={() => {
          setEditTripModal(false);
          fetchTripData();
        }}
        footer={null}
        centered
      >
        {TripDetailsModal()}
      </Modal>
      {/* confirmation modals */}
      <ConfirmModal
        isOpen={confirmModal}
        setIsOpen={setConfirmModal}
        title={"Delete ?"}
        description={"Do you want to delete this trip?"}
        button1={"No"}
        button2={"Yes"}
        onClickButton1={() => setConfirmModal(!confirmModal)}
        onClickButton2={() => {
          setDeleteModal(!deleteModal);
          deleteTrip();
        }}
      />
      <SuccessModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        animation="delete"
        title={"Trip deleted successfully."}
        onClose={() => navigate("/trip-management")}
      />

      <Modal
        title={
          trip?.routeId +
          " / " +
          trip?.routeName +
          " / " +
          trip?.tripType +
          " / " +
          DateUtils.convertDate(trip?.tripDate)
        }
        visible={statusModal}
        width={"630px"}
        onCancel={() => {
          setStatusModal(false);
          setTripEndTime(null);
        }}
        footer={null}
        centered
        className="my-6"
      >
        {StatusModalContent()}
      </Modal>
    </>
  );
}
